import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionAccordion from "../components/QuestionAccordion";
import UnderlineB from "../components/UnderlineB";

function FacialTrauma() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          Facial Trauma
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography sx={{ marginBottom: "50px" }} className="listcolors">
        Injuries to the face, by their very nature, impart a high degree of
        emotional, as well as physical trauma to patients. The science and art
        of treating these injuries requires special training involving a “hands
        on” experience and an understanding of how the treatment provided will
        influence the patient’s long term function and appearance. Oral &
        Maxillofacial Surgeons meet and exceed these modern standards. They are
        trained, skilled and uniquely qualified to manage and treat facial
        trauma. Your surgeon is on staff at Windsor Regional and Hotel Dieu
        Hospital and delivers emergency room coverage for facial injuries, which
        include the following conditions:
        <ul>
          <li>Facial lacerations</li>
          <li>Intra oral lacerations</li>
          <li>Avulsed (knocked out) teeth</li>
          <li>
            Fractured facial bones (cheek, nose or portions of the eye socket)
          </li>
          <li>Fractured jaws (upper and lower jaw)</li>
          <li>Fractured Frontal bones</li>
        </ul>
      </Typography>

      <QuestionAccordion title="The Nature of Maxillofacial Trauma">
        There are a number of possible causes of facial trauma such as motor
        vehicle accidents, accidental falls, sports injuries, interpersonal
        violence and work related injuries. Types of facial injuries can range
        from injuries of teeth to extremely severe injuries of the skin and
        bones of the face. Typically, facial injuries are classified as either
        soft tissue injuries (skin and gums), bone injuries (fractures), or
        injuries to special regions (such as the eyes, facial nerves or the
        salivary glands).
      </QuestionAccordion>
      <QuestionAccordion title="Soft Tissue Injuries of the Maxillofacial Region">
        When soft tissue injuries such as lacerations occur on the face, they
        are repaired by suturing. In addition to the obvious concern of
        providing a repair that yields the best cosmetic result possible, care
        is taken to inspect for and treat injuries to structures such as facial
        nerves, salivary glands and salivary ducts (or outflow channels). Your
        surgeon has trained to be proficient at diagnosing and treating all
        types of facial lacerations.
      </QuestionAccordion>
      <QuestionAccordion title="Bone Injuries of the Maxillofacial Region">
        Fractures to the bones of the face are treated in a manner similar to
        the fractures in other parts of the body. The specific form of treatment
        is determined by various factors, which include the location of the
        fracture, the severity of the fracture, the age and general health of
        the patient. When an arm or a leg is fractured, a cast is often applied
        to stabilize the bone to allow for proper healing. Since a cast cannot
        be placed on the face, other means have been developed to stabilize
        facial fractures. One of these options involves wiring the jaws together
        for certain fractures of the upper and/or lower jaw. <br /> <br />
        Certain other types of fractures of the jaw are best treated and
        stabilized by the surgical placement of small plates and screws at the
        involved site. This technique of treatment can often allow for healing
        and elimininates the necessity of having the jaws wired together. This
        technique is called “rigid fixation” of a fracture. The relatively
        recent development and use of rigid fixation has profoundly improved the
        recovery period for many patients, allowing them to return to normal
        function more quickly. The treatment of facial fractures should be
        accomplished in a thorough and predictable manner. More importantly, the
        patient’s facial appearance should be minimally affected. An attempt at
        accessing the facial bones through the fewest incisions necessary is
        always made. At the same time, the incisions that become necessary, are
        designed to be small and, whenever possible, are placed so that the
        resultant scar is hidden.
      </QuestionAccordion>
      <QuestionAccordion title="Injuries to the Teeth and Surrounding Dental Structures">
        Isolated injuries to teeth are quite common and may require the
        expertise of various dental specialists. Oral surgeons usually are
        involved in treating fractures in the supporting bone or in replanting
        teeth that have been displaced or knocked out. These types of injuries
        are treated by one of a number of forms of splinting (stabilizing by
        wiring or bonding teeth together). If a tooth is knocked out, it should
        be placed in salt water or milk. The sooner the tooth is re-inserted
        into the dental socket, the better chance it will survive. Therefore,
        the patient should see a dentist or oral surgeon as soon as possible.
        Never attempt to wipe the tooth off, since remnants of the ligament that
        hold the tooth in the jaw are attached and are vital to the success of
        replanting the tooth. Other dental specialists may be called upon such
        as endodontists, who may be asked to perform root canal therapy, and/or
        restorative dentists who may need to repair or rebuild fractured teeth.
        In the event that injured teeth cannot be saved or repaired, dental
        implants are often now utilized as replacements for missing teeth. The
        proper treatment of facial injuries is now the realm of specialists who
        are well versed in emergency care, acute treatment, long term
        reconstruction and rehabilitation of the patient.
      </QuestionAccordion>
    </Grid>
  );
}

export default FacialTrauma;
