import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function SimpleAccordion(props) {
  let textt = props.text?.split("\n").map((item, i) => <p key={i}>{item}</p>);

  return (
    <div>
      <Accordion
        sx={{
          border: "none",
          boxShadow: "none",
          "&:before": {
            display: "none",
          },

          "& .MuiAccordionSummary-root:hover": {
            color: "teal.main",
            boxShadow: `-3px 0 0 0 #3A959B`,
            cursor: "pointer",
            " .MuiSvgIcon-root": {
              color: "teal.main",
            },
          },

          "& .MuiAccordionSummary-root.Mui-expanded": {
            color: "teal.main",
            boxShadow: "-3px 0 0 0 #3A959B",
            cursor: "pointer",
          },
          "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
            color: "teal.main",
          },
          "& .MuiAccordionSummary-expandIcon.Mui-expanded": {
            color: "teal.main",
          },
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls={`${props.title}-content`}
          id={`${props.title}-header`}
          sx={{
            width: "100%",
            maxWidth: "500px",
          }}
        >
          <Typography variant="h6" color="text.main">
            {props.title}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="text.medium">
            {textt} {props.children}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
