import React from "react";
import { animated, useSpring } from "react-spring";

function FadeWrapper(props) {
  // const styles = useSpring({ opacity: show ? 1 : 0 });
  const styles = useSpring({
    to: { opacity: 1 },
    from: { opacity: props.start ? props.start : 0 },
    delay: props.delay ? props.delay : 0,
    config: { duration: props.duration },
  });

  return <animated.span style={styles}>{props.children}</animated.span>;
}

export default FadeWrapper;
