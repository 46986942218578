// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".offers_fill__P4-Qv {\n    width: 100px;\n    height: 4px;\n    background-color: hotpink;\n    margin: 0 auto;\n    margin-bottom: 40px;\n}\n.offers_fillb__SuA3Q {\n    width: 100px;\n    height: 3px;\n    margin-bottom: 40px;\n    background-image: linear-gradient(to left, rgba(255, 0, 0, 0) , #114c74 50%);\n    background-repeat: no-repeat;\n}\n\n.offers_underline2__V4yB7 {\n    height: 4px;\n    background-color: hotpink;\n    margin: 0 auto;\n    margin-bottom: 40px;\n}", "",{"version":3,"sources":["webpack://./src/css/offers.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,YAAY;IACZ,WAAW;IACX,mBAAmB;IACnB,4EAA4E;IAC5E,4BAA4B;AAChC;;AAEA;IACI,WAAW;IACX,yBAAyB;IACzB,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".fill {\n    width: 100px;\n    height: 4px;\n    background-color: hotpink;\n    margin: 0 auto;\n    margin-bottom: 40px;\n}\n.fillb {\n    width: 100px;\n    height: 3px;\n    margin-bottom: 40px;\n    background-image: linear-gradient(to left, rgba(255, 0, 0, 0) , #114c74 50%);\n    background-repeat: no-repeat;\n}\n\n.underline2 {\n    height: 4px;\n    background-color: hotpink;\n    margin: 0 auto;\n    margin-bottom: 40px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fill": "offers_fill__P4-Qv",
	"fillb": "offers_fillb__SuA3Q",
	"underline2": "offers_underline2__V4yB7"
};
export default ___CSS_LOADER_EXPORT___;
