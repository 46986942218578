import { Typography, styled } from "@mui/material";
import React from "react";
import stars1 from "../img/stars1.png";
import whitebg1 from "../img/whitebg1.jpg";

const A = styled("a")({});

function Review(props) {
  return (
    <A
      href={[props.link]}
      target="_blank"
      sx={{
        marginTop: {
          xs: `${props.topxs}`,
          md: `${props.topmd}`,
          lg: `${props.toplg}`,
        },
        padding: { xs: "20px 20px", sm: "50px 50px" },
        display: "flex",
        textDecoration: "none",
        color: "#222222",
        flexDirection: "column",
        alignItems: "center",
        width: "450px",
        height: "450px",
        borderRadius: { xs: "10%", sm: "50%" },
        margin: "10px",
        backgroundImage: `url(${whitebg1})`,
        backgroundSize: "cover",
        boxShadow: "5px 5px 10px rgb(0,0,0,0.2)",
        overflow: "hidden",
        textOverflow: "ellipsis",
        "&:hover": {
          transition: "all .2s ease-in-out",
          transform: "scale(1.1)",
          transformOrigin: "center center",
        },
      }}
    >
      <Typography variant="h6">{props.name}</Typography>
      <img
        width="100px"
        src={stars1}
        alt="5star"
        style={{ marginBottom: "15px" }}
      />

      <Typography
        variant="body2"
        color="text.medium"
        sx={{ borderRadius: "50px" }}
      >
        {props.text}
      </Typography>
    </A>
  );
}

export default Review;
