import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import UnderlineB from "../components/UnderlineB";

const TealButton = styled(Link)({
  display: "inline-flex",
  justifyContent: "center",
  textDecoration: "none",
  border: "2px solid #3A959B",
  color: "#3A959B",
  margin: "10px auto",
  padding: "5px 20px",
  width: "240px",
  backgroundImage: "linear-gradient(#3A959B, #3A959B)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "white",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});
function Values() {
  return (
    <Grid container sx={{ padding: "10px 10%", margin: "0 auto" }}>
      <Grid
        item
        container
        xs={12}
        sm={4}
        flexDirection="column"
        alignItems={{ xs: "center", sm: "flex-start" }}
        sx={{ marginBottom: "30px" }}
      >
        <Box
          sx={{
            width: "90%",
            borderTop: "1px solid grey",
            marginBottom: "30px",
          }}
        ></Box>
        <Typography variant="h4">
          Our Values
          <UnderlineB />
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={4}
        flexDirection="column"
        sx={{ marginBottom: "30px" }}
      >
        <Box
          sx={{
            width: "90%",
            borderTop: "1px solid grey",
            marginBottom: "30px",
            display: { xs: "none", sm: "block" },
          }}
        ></Box>
        <Typography variant="h5" sx={{ marginBottom: "20px" }}>
          Quality
        </Typography>
        <Typography sx={{ paddingRight: "5%" }}>
          Great Lakes Oral & Maxillofacial Surgery Centre takes great pride in
          bringing their patients the latest in technologically advanced,
          comfort oriented oral and maxillofacial surgical care.
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={4}
        flexDirection="column"
        sx={{ marginBottom: "30px" }}
      >
        <Box
          sx={{
            width: "90%",
            borderTop: "1px solid grey",
            marginBottom: "30px",
            display: { xs: "none", sm: "block" },
          }}
        ></Box>
        <Typography variant="h5" sx={{ marginBottom: "20px" }}>
          Health
        </Typography>
        <Typography sx={{ paddingRight: "5%" }}>
          Both of our Tecumseh and Chatham offices are independently inspected
          outpatient surgical facilities, and are equipped with modern
          specialized equipment. They are staffed with a highly trained,
          experienced and compassionate team of caring professionals.
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={4}
        flexDirection="column"
        alignItems={{ xs: "center", sm: "flex-start" }}
        sx={{ marginBottom: "30px" }}
      >
        <Typography variant="h4" sx={{ marginTop: "30px" }}>
          About us
          <UnderlineB />
        </Typography>
      </Grid>
      <Grid
        item
        container
        xs={12}
        sm={8}
        flexDirection="column"
        alignItems={{ xs: "center", sm: "flex-start" }}
        sx={{ marginBottom: "30px" }}
      >
        <Typography sx={{ marginTop: "30px" }}>
          Great Lakes Oral & Maxillofacial Surgery Centre is a team of highly
          trained and experienced professionals committed to providing the
          highest quality of care to their patients. Their expertise includes
          the diagnosis and treatment of oral and maxillofacial problems,
          ranging from simple to complex procedures. They offer advanced
          technologically equipped outpatient surgical facilities with certified
          medical specialists in anesthesia, ensuring optimal care and safety.
          The team takes great pride in providing their patients with a
          comfortable and pleasant experience while using the latest technology
          in oral and maxillofacial surgical care.
        </Typography>
      </Grid>
      <TealButton to={"about"}>Learn More</TealButton>
    </Grid>
  );
}

export default Values;
