import { Backdrop, Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import UnderlineT from "../components/UnderlineT";
import GreatLakesLogo from "../img/GreatLakesLogo.png";
import alicia1 from "../img/alicia1.jpg";
import brandi1 from "../img/brandi1.jpg";
import brittney1 from "../img/brittney1.jpg";
import cierra1 from "../img/cierra1.jpg";
import dina1 from "../img/dina1.jpg";
import erin1 from "../img/erin1.jpg";
import jenn1 from "../img/jenn1.jpg";
import marilyn1 from "../img/marilyn1.jpg";
import maryjo1 from "../img/maryjo1.jpg";
import michelle1 from "../img/michelle1.jpg";
import samantha1 from "../img/samantha1.jpg";
import samantha2 from "../img/samantha2.jpg";
import tammy1 from "../img/tammy1.jpg";

const Img = styled("img")(({ theme }) => ({
  maxWidth: "200px",
  borderRadius: "100%",
  "&:hover": {
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    transform: "scale(1.05)",
    transformOrigin: "center center",
    boxShadow: "0 0 10px 10px rgb(0,0,0,0.2)",
  },
}));

function Person(props) {
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  return (
    <Grid
      item
      sx={{
        justifyContent: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <Box
          sx={{
            backgroundColor: "rgb(240,245,250)",
            width: "95%",
            maxWidth: "600px",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
            boxSizing: "border-box",
            flexDirection: { xs: "column" },
          }}
        >
          <Typography variant="h6" color="blue.main">
            {props.name}
          </Typography>
          <Typography
            variant="subtitle2"
            color="teal.main"
            sx={{ maxWidth: "20ch", textAlign: "center", marginBottom: "30px" }}
          >
            {props.role}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Img src={props.image} sx={{ margin: "20px" }} />
            <Typography color="text.primary">{props.description}</Typography>
          </Box>
        </Box>
      </Backdrop>

      <Img src={props.image} alt={props.name} onClick={handleOpen} />
      <Typography variant="h6" color="blue.main">
        {props.name}
      </Typography>
      <Typography
        variant="subtitle2"
        color="teal.main"
        sx={{ maxWidth: "20ch", textAlign: "center" }}
      >
        {props.role}
      </Typography>
    </Grid>
  );
}

function Staff() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        padding: "150px 0px",
      }}
    >
      <Helmet>
        <title>Staff - Great Lakes Oral & Maxillofacial Surgery Centre</title>
        <meta
          name="description"
          content="Meet the amazing team at Great Lakes Oral & Maxillofacial Surgery Centre. We have over 15 staff, dedicated to providing the care and service you deserve."
        />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={GreatLakesLogo} />
        <meta
          property="og:title"
          content="Staff - Great Lakes Oral & Maxillofacial Surgery Centre"
        />
        <meta
          property="og:url"
          content="http://www.greatlakessurgerycentre.com/staff"
        />
        <meta
          property="og:description"
          content="Meet the amazing team at Great Lakes Oral & Maxillofacial Surgery Centre. We have over 15 staff, dedicated to providing the care and service you deserve."
        />
        {/* End Facebook tags */}
        {/* Twitter tags */}

        {/* End Twitter tags */}
      </Helmet>
      {/* <Typography
        variant="h4"
        textAlign="center"
        sx={{ marginBottom: "100px" }}
      >
        Our Amazing Staff
        <UnderlineT />
      </Typography>
      <Grid
        container
        spacing={{ xs: 5, sm: 8, md: 10, xl: 14 }}
        justifyContent="center"
      >
        <Person
          image={brandi1}
          name="Brandi"
          role="Office Manager/Level II Dental Assistant"
          description="Brandi is a level II dental assistant who graduated from Fanshawe College in 2000. She is H.A.R.P., First Aid and CPR certified. Brandi has worked in oral surgery since 2001 and has worked as Dr. Shuren’s assistant since 2003 as both a surgical assistant and office administrator."
        />

        <Person
          image={alicia1}
          name="Alicia"
          role="Registered Nurse"
          description="Alicia graduated in 2015 with her Bachelor of Science in Nursing at the University of Windsor-collaboration program. She has been with Great Lakes Oral & Maxillofacial Surgery Centre since January 2016. She works in surgery and in the recovery rooms. Alicia is ACLS, PALS, CPR and First Aid certified."
        />
        <Person
          image={erin1}
          name="Erin"
          role="Receptionist"
          description="Erin graduated in 2015 from St. Clair College’s Medical Administration Program. Erin has over 2 years of experience helping patients and being a welcoming face of a busy office. Erin joined the Team at Great Lakes Oral & Maxillofacial Surgery Centre in 2017"
        />
        <Person
          image={marilyn1}
          name="Marilyn"
          role="Registered Nurse"
          description="Marilyn is the recovery nurse at Great Lakes Oral & Maxillofacial Surgery Centre. She has been a Registered Nurse since 2001. Marilyn is also currently employeed by St. John’s Hospital in Grosse Point, MI as a Medical/Surgical nurse. She enjoys working in the medical and dental fields. Marilyn is current in ACLS, PALS, First Aid and CPR. Marilyn can be found in the recovery room taking care of patients after surgical procedures."
        />
        <Person
          image={samantha1}
          name="Samantha"
          description="Samantha obtained her Bachelor of Arts Honors Degree from the University of Windsor. She is continuing her education. She joined the Team at Great Lakes Oral & Maxillofacial Surgery Centre in November of 2017."
        />

        <Person
          image={samantha2}
          name="Samantha"
          role="Registered Nurse"
          description="Samantha Graduated from the Collaborative Honours Bachelor of Science in Nursing program at The University of Windsor in 2011. Upon graduation, she worked as a community shift nurse in Ottawa, primarily with pediatric clients. She returned to the Windsor-Essex Area & has been at Great Lakes Oral Surgery Centre for over a year. Samantha is ACLS & PALS certified."
        />
        <Person
          image={tammy1}
          name="Tammy"
          role="Level II Dental Assistant"
          description="Tammy began working at Great Lakes in June 2010 upon graduating from Everest College. She is a Level II Certified Dental Assistant who is H.A.R.P. certified and current in First Aid & CPR."
        />
        <Person
          image={michelle1}
          name="Michelle"
          role="Level II Dental Assistant"
          description="Michelle is a Level II Dental Assistant. She graduated in 2013 from St. Clair College and started working immediately in general dentistry. Michelle then joined Great Lakes Oral & Maxillofacial Surgery Centre in September of 2017. She is HARP/CPR/First Aid Certified. When not working, Michelle spends time with her husband and 3 children."
        />

        <Person
          image={cierra1}
          name="Cierra"
          role="Registered Nurse"
          description="Cierra graduated from the University of Windsor in 2018 with her Bachelors of Science in Nursing. Cierra is CPR, ACLS, and PALS certified and works in both the surgery and recovery rooms. Cierra has been with our team since March 2019."
        />
        <Person
          image={dina1}
          name="Dina"
          role="Level II Dental Assistant"
          description="Dina graduated from St. Clair College in 2008. She is a H.A.R.P., first aid, and CPR certified Level II Dental Assistant. Dina has over 10 years experience in general dentistry and is now working as a Level II Dental Assistant for Dr. Shuren."
        />
      </Grid> */}
      <Typography variant="h5">Staff page coming soon!</Typography>
    </Box>
  );
}

export default Staff;
