import DownloadIcon from "@mui/icons-material/Download";
import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import UnderlineB from "../components/UnderlineB";
import UnderlineT from "../components/UnderlineT";
import AnesthesiaLaschukTec from "../documents/AnesthesiaLaschukTec.pdf";
import AnesthesiaLaschukChat from "../documents/AnesthesiaLaschukChat.pdf";
import AnesthesiaShurenTec from "../documents/AnesthesiaShurenTec.pdf";
import AnesthesiaStaplefordTec from "../documents/AnesthesiaStaplefordTec.pdf";
import AnesthesiaWalkerTec from "../documents/AnesthesiaWalkerTec.pdf";
import AnesthesiaWalkerChat from "../documents/AnesthesiaWalkerChat.pdf";
import BeforeSurgery from "../documents/BeforeSurgery.pdf";
import ConeBeamCT from "../documents/ConeBeamCT.pdf";
import Consultation from "../documents/Consultation.pdf";
import FinancialInformation from "../documents/FinancialInformation.pdf";
import ImplantsLaschukTec from "../documents/ImplantsLaschukTec.pdf";
import ImplantsLaschukChat from "../documents/ImplantsLaschukChat.pdf";
import ImplantsShuren from "../documents/ImplantsShuren.pdf";
import ImplantsStapleford from "../documents/ImplantsStapleford.pdf";
import ImplantsWalkerTec from "../documents/ImplantsWalkerTec.pdf";
import ImplantsWalkerChat from "../documents/ImplantsWalkerChat.pdf";
import PrivacyCode from "../documents/PrivacyCode.pdf";
import Questionaire from "../documents/Questionaire.pdf";
import Scheduling from "../documents/Scheduling.pdf";
import GreatLakesLogo from "../img/GreatLakesLogo.png";
import GLOMSConsult from "../documents/GLOMSConsult.pdf";

const A = styled("a")({
  display: "flex",
  width: "max-content",
  textDecoration: "none",
  alignItems: "center",
  margin: "10px",
  backgroundImage: "radial-gradient(rgb(68, 199, 205,0.3), white)",
  backgroundSize: "0% 0%",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  transition: "background-size .5s, color .5s",
  transformOrigin: "center",

  "&:hover": {
    // transition: "all .2s ease-in-out",
    // transform: "scale(1.1)",
    backgroundSize: "95% 40%",
    backgroundPostition: "center",
  },
});

function Item(props) {
  return (
    <A href={props.link} target="_blank" rel="noreferrer" alt="Download form">
      <DownloadIcon
        sx={{ display: "inline-flex" }}
        fontSize="large"
        color="teal"
      />
      <Typography color="text.primary" sx={{ display: "inline-flex" }}>
        {props.text}
      </Typography>
    </A>
  );
}

function Downloads() {
  return (
    <>
      <Helmet>
        <title>
          Downloads - Great Lakes Oral & Maxillofacial Surgery Centre
        </title>
        <meta
          name="description"
          content="Download patient forms, including consent for implants and anesthesia, at Great Lakes Oral & Maxillofacial Surgery Centre."
        />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={GreatLakesLogo} />
        <meta
          property="og:title"
          content="Downloads - Great Lakes Oral & Maxillofacial Surgery Centre"
        />
        <meta
          property="og:url"
          content="http://www.greatlakessurgerycentre.com/downloads"
        />
        <meta
          property="og:description"
          content="Download patient forms, including consent for implants and anesthesia, at Great Lakes Oral & Maxillofacial Surgery Centre."
        />
        {/* End Facebook tags */}
        {/* Twitter tags */}

        {/* End Twitter tags */}
      </Helmet>
      <Grid
        container
        justifyContent="space-evenly"
        sx={{
          minHeight: "100vh",
          padding: "150px 0",
        }}
      >
        <Typography variant="h4" sx={{ width: "100%", textAlign: "center" }}>
          Forms and Downloads <UnderlineT />
        </Typography>
        <Grid
          container
          item
          sx={{ maxWidth: "max-content", margin: "2%" }}
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h5" sx={{ marginTop: "40px" }}>
            Anesthesia Consent Forms
            <UnderlineB />
          </Typography>
          <Box>
            <Item
              text="Anesthesia - Dr. Shuren's Patients"
              link={AnesthesiaShurenTec}
            />
            <Item
              text="Anesthesia - Dr. Stapleford's Patients"
              link={AnesthesiaStaplefordTec}
            />
            <Item
              text="Anesthesia - Dr. Walker's Patients - Tecumseh"
              link={AnesthesiaWalkerTec}
            />
            <Item
              text="Anesthesia - Dr. Walker's Patients - Chatham"
              link={AnesthesiaWalkerChat}
            />
            <Item
              text="Anesthesia - Dr. Laschuk's Patients - Tecumseh"
              link={AnesthesiaLaschukTec}
            />
            <Item
              text="Anesthesia - Dr. Laschuk's Patients - Chatham"
              link={AnesthesiaLaschukChat}
            />
          </Box>
        </Grid>

        <Grid
          item
          container
          flexDirection="column"
          alignItems="center"
          sx={{ maxWidth: "max-content", margin: "2%" }}
        >
          <Typography variant="h5" sx={{ marginTop: "40px" }}>
            Implant Consent Forms
            <UnderlineB />
          </Typography>
          <Box>
            <Item
              text="Implants - Dr. Shuren's Patients"
              link={ImplantsShuren}
            />
            <Item
              text="Implants - Dr. Stapleford's Patients"
              link={ImplantsStapleford}
            />
            <Item
              text="Implants - Dr. Walker's Patients - Tecumseh"
              link={ImplantsWalkerTec}
            />
            <Item
              text="Implants - Dr. Walker's Patients - Chatham"
              link={ImplantsWalkerChat}
            />
            <Item
              text="Implants - Dr. Laschuk's Patients - Tecumseh"
              link={ImplantsLaschukTec}
            />
            <Item
              text="Implants - Dr. Laschuk's Patients - Chatham"
              link={ImplantsLaschukChat}
            />
          </Box>
        </Grid>
        <Grid
          item
          container
          sx={{ maxWidth: "max-content", margin: "2%" }}
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h5" sx={{ marginTop: "40px" }}>
            Patient Downloads
            <UnderlineB />
          </Typography>
          <Box>
            <Item text="Consultation" link={Consultation} />
            <Item text="Scheduling Information" link={Scheduling} />
            <Item text="Patient Billing" link={FinancialInformation} />
            <Item text="Health Questionaire" link={Questionaire} />
            <Item text="Privacy Policy" link={PrivacyCode} />
            <Item text="Instructions Before Surgery" link={BeforeSurgery} />
            <Item text="Cone Beam CT Scan Referral" link={ConeBeamCT} />
          </Box>
        </Grid>
        <Grid
          container
          item
          sx={{ maxWidth: "max-content", margin: "2%" }}
          flexDirection="column"
          alignItems="center"
        >
          <Typography variant="h5" sx={{ marginTop: "40px" }}>
            Patient Referral Forms
            <UnderlineB />
          </Typography>
          <Box>
            <Item
              text="TMJ Surgical Consult Request Questionnaire"
              link={GLOMSConsult}
            />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Downloads;
