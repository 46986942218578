import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import { NavLink, Navigate, Route, Routes } from "react-router-dom";
import { theme } from "../Theme";
import UnderlineT from "../components/UnderlineT";
import GreatLakesLogo from "../img/GreatLakesLogo.png";
import tooth1 from "../img/tooth1.png";
import tooth10 from "../img/tooth10.png";
import tooth11 from "../img/tooth11.png";
import tooth12 from "../img/tooth12.png";
import tooth2 from "../img/tooth2.png";
import tooth3 from "../img/tooth3.png";
import tooth6 from "../img/tooth6.png";
import AfterFacialTrauma from "./AfterFacialTrauma";
import AfterWisdom from "./AfterWisdom";
import ExposureImpact from "./ExposureImpact";
import GeneralInstructions from "./GeneralInstructions";
import MultipleTeeth from "./MultipleTeeth";
import Placement from "./Placement";
import ToothRemoval from "./ToothRemoval";

const SurgeryDrop = styled(NavLink)(({ theme }) => ({
  backgroundColor: "white",
  display: "flex",
  alignItems: "center",
  minHeight: "40px",
  textDecoration: "none",
  width: "100%",
  maxWidth: "250px",
  margin: "10px",
  color: theme.palette.text.primary,

  "&:hover": {
    backgroundColor: theme.palette.teal.main,
    cursor: "pointer",
    color: theme.palette.text.white,
  },
  "&:hover img": {
    filter: "brightness(0) invert(1)",
  },
}));

let activeDrop = {
  backgroundColor: theme.palette.teal.main,
  color: theme.palette.text.white,
};

let activeImg = {
  filter: "brightness(0) invert(1)",
};

const SurgeryImg = styled("img")(({ theme }) => ({
  margin: "5px 10px",
  width: "50px",
}));
function SurgeryItem(props) {
  return (
    <SurgeryDrop
      to={`${props.text.toLowerCase().replaceAll(" ", "-")}`}
      style={({ isActive }) => (isActive ? activeDrop : null)}
    >
      {({ isActive }) => (
        <>
          <SurgeryImg
            style={isActive ? activeImg : null}
            src={props.image}
            alt={props.text}
          />
          <Typography variant="h6">{props.text}</Typography>
        </>
      )}
    </SurgeryDrop>
  );
}

function AfterSurgery() {
  return (
    <>
      <Helmet>
        <title>
          After Surgery - Great Lakes Oral & Maxillofacial Surgery Centre
        </title>
        <meta
          name="description"
          content="We provide clear instructions on the steps to take after surgery with us, no matter the procedure. Our team will guide you through the necessary steps to ensure a successful and speedy recovery."
        />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={GreatLakesLogo} />
        <meta
          property="og:title"
          content="After Surgery - Great Lakes Oral & Maxillofacial Surgery Centre"
        />
        <meta
          property="og:url"
          content="http://www.greatlakessurgerycentre.com/after-surgery"
        />
        <meta
          property="og:description"
          content="We provide clear instructions on the steps to take after surgery with us, no matter the procedure. Our team will guide you through the necessary steps to ensure a successful and speedy recovery."
        />
        {/* End Facebook tags */}
        {/* Twitter tags */}

        {/* End Twitter tags */}
      </Helmet>
      <Box sx={{ minHeight: "100vh", padding: "150px 0px" }}>
        <Grid container>
          <Grid item container flexDirection="column" alignItems="center">
            <Typography
              variant="h4"
              sx={{ marginBottom: { xs: "30px", sm: "70px" } }}
            >
              After a Procedure
              <UnderlineT />
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={4}
            flexDirection="column"
            alignItems={{ xs: "center", md: "flex-end" }}
            sx={{
              padding: "20px",
              paddingTop: "0px",
              borderRight: { xs: "none", sm: "2px solid grey" },
            }}
          >
            <SurgeryItem text="General Instructions" image={tooth10} />
            <SurgeryItem text="Tooth Removal" image={tooth3} />
            <SurgeryItem text="Wisdom Teeth Removal" image={tooth2} />
            <SurgeryItem text="Facial Trauma" image={tooth6} />
            <SurgeryItem text="Exposure of Impacted Tooth" image={tooth11} />
            <SurgeryItem text="Placement of Dental Implants" image={tooth1} />
            <SurgeryItem text="Removal of Multiple Teeth" image={tooth12} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={8}
            flexDirection="column"
            sx={{ padding: "30px", paddingTop: "0px" }}
          >
            <Routes>
              <Route path="tooth-removal" element={<ToothRemoval />} />
              <Route
                path="general-instructions"
                element={<GeneralInstructions />}
              />
              <Route
                path="/"
                element={<Navigate to="general-instructions" />}
              />
              <Route
                path="*"
                element={<Navigate to="general-instructions" />}
              />
              <Route path="wisdom-teeth-removal" element={<AfterWisdom />} />
              <Route
                path="placement-of-dental-implants"
                element={<Placement />}
              />
              <Route path="facial-trauma" element={<AfterFacialTrauma />} />
              <Route
                path="removal-of-multiple-teeth"
                element={<MultipleTeeth />}
              />
              <Route
                path="exposure-of-impacted-tooth"
                element={<ExposureImpact />}
              />
            </Routes>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default AfterSurgery;
