import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import FadeWrapper from "../components/FadeWrapper";
import UnderlineB from "../components/UnderlineB";
import UnderlineT from "../components/UnderlineT";
import address1 from "../img/address1.png";
import calendar1 from "../img/calendar1.png";
import mail1 from "../img/mail1.png";
import mapchat from "../img/mapchat.png";
import maptec2 from "../img/maptec2.png";
import phone1 from "../img/phone1.png";
import faxt from "../img/faxt.png";

const A = styled("a")(({ theme }) => ({
  textDecoration: "none",
  display: "flex",
  alignItems: "center",
  color: theme.palette.text.primary,
  "&:hover": {
    cursor: "pointer",
    transition: "all .2s ease-in-out",
    transform: "scale(1.05)",
    transformOrigin: "center center",
  },
}));
const Img = styled("img")(({ theme }) => ({}));

function Chatham() {
  return (
    <FadeWrapper delay={100} duration={1000}>
      <Grid
        item
        direction="column"
        container
        alignItems="center"
        sx={{ width: "100%", marginTop: "50px" }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: "30px", textAlign: "center" }}
        >
          Chatham
          <UnderlineB width="70%" />
        </Typography>
        <Grid
          item
          container
          flexDirection="column"
          sx={{ width: "max-content" }}
          spacing={4}
        >
          <Grid item container alignItems="center">
            <A href="tel:519-397-1177">
              <Img
                src={phone1}
                alt="phone"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="blue.main">
                  Phone
                </Typography>
                <Typography>519-397-1177</Typography>
              </Grid>
            </A>
          </Grid>
          <Grid item container alignItems="center">
            <A href={"javascript:void(0)"}>
              <Img
                src={faxt}
                alt="fax"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="teal.main">
                  Fax
                </Typography>
                <Typography sx={{ maxWidth: "250px" }}>519-397-2211</Typography>
              </Grid>
            </A>
          </Grid>
          <Grid item container alignItems="center">
            <A
              href="https://goo.gl/maps/8twXFeLzgmmcQmiH8"
              rel="noreferrer"
              target="_blank"
            >
              <Img
                src={address1}
                alt="phone"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="teal.main">
                  Address
                </Typography>
                <Typography sx={{ maxWidth: "250px" }}>
                  197 St. Clair Street | ON | N7L 3J4
                </Typography>
              </Grid>
            </A>
          </Grid>
          {/* <Grid item container alignItems="center">
            <A href="mailto: info@greatlakessurgerycentre.com">
              <Img
                src={mail1}
                alt="mail"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="teal.main">
                  Email
                </Typography>
                <Typography>info@greatlakessurgerycentre.com</Typography>
              </Grid>
            </A>
          </Grid> */}
          <Grid item container>
            <Img
              src={calendar1}
              alt="calendar"
              sx={{
                marginRight: { xs: "20px", sm: "30px" },
                width: { xs: "40px", sm: "60px" },
                height: { xs: "40px", sm: "60px" },
              }}
            />
            <Grid item sx={{ minWidth: "220px" }}>
              <Typography variant="h6" color="teal.main">
                Hours of Operation
              </Typography>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Monday</Typography>
                closed
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Tuesday</Typography>
                8am - 5pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Wednesday</Typography>
                closed
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Thursday</Typography>
                8am - 5pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Friday</Typography>
                8am - 3pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Sat-Sun</Typography>
                Closed
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <A
          href="https://goo.gl/maps/8twXFeLzgmmcQmiH8"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={mapchat}
            style={{
              width: "90%",
              maxWidth: "500px",
              marginTop: "50px",
              marginBottom: "20px",
            }}
            alt="map"
          ></img>
        </A>
      </Grid>
    </FadeWrapper>
  );
}
function Tecumseh() {
  return (
    <FadeWrapper delay={100} duration={1000}>
      <Grid
        item
        direction="column"
        container
        alignItems="center"
        sx={{ width: "100%", marginTop: "50px" }}
      >
        <Typography
          variant="h4"
          sx={{
            marginBottom: "30px",
            width: "max-content",
          }}
        >
          Tecumseh
          <UnderlineB width="70%" />
        </Typography>
        <Grid
          item
          container
          flexDirection="column"
          sx={{ width: "max-content" }}
          spacing={4}
        >
          <Grid item container alignItems="center">
            <A href="tel:519-979-7227">
              <Img
                src={phone1}
                alt="phone"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="blue.main">
                  Phone
                </Typography>
                <Typography>519-979-7227</Typography>
              </Grid>
            </A>
          </Grid>
          <Grid item container alignItems="center">
            <A href={"javascript:void(0)"}>
              <Img
                src={faxt}
                alt="fax"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="teal.main">
                  Fax
                </Typography>
                <Typography sx={{ maxWidth: "250px" }}>519-979-1956</Typography>
              </Grid>
            </A>
          </Grid>
          <Grid item container alignItems="center">
            <A
              href="https://maps.app.goo.gl/RR1CFZ7nuH5dH26KA"
              rel="noreferrer"
              target="_blank"
            >
              <Img
                src={address1}
                alt="address"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="teal.main">
                  Address
                </Typography>
                <Typography sx={{ maxWidth: "250px" }}>
                  1825 Manning Rd | Studio 700 Tecumseh | ON | N9K 0A9
                </Typography>
              </Grid>
            </A>
          </Grid>
          {/* <Grid item container alignItems="center">
            <A href="mailto: info@greatlakessurgerycentre.com">
              <Img
                src={mail1}
                alt="mail"
                sx={{
                  marginRight: { xs: "20px", sm: "30px" },
                  width: { xs: "40px", sm: "60px" },
                  height: { xs: "40px", sm: "60px" },
                }}
              />
              <Grid item>
                <Typography variant="h6" color="teal.main">
                  Email
                </Typography>
                <Typography variant="subtitle1">
                  info@greatlakessurgerycentre.com
                </Typography>
              </Grid>
            </A>
          </Grid> */}
          <Grid item container>
            <Img
              src={calendar1}
              alt="calendar"
              sx={{
                marginRight: { xs: "20px", sm: "30px" },
                width: { xs: "40px", sm: "60px" },
                height: { xs: "40px", sm: "60px" },
              }}
            />
            <Grid item sx={{ minWidth: "220px" }}>
              <Typography variant="h6" color="teal.main">
                Hours of Operation
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Monday</Typography>
                8am - 5pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Tuesday</Typography>
                8am - 5pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Wednesday</Typography>
                8am - 5pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Thursday</Typography>
                8am - 5pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Friday</Typography>
                8am - 3pm
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography>Sat-Sun</Typography>
                Closed
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <A
          href="https://maps.app.goo.gl/RR1CFZ7nuH5dH26KA"
          rel="noreferrer"
          target="_blank"
        >
          <img
            src={maptec2}
            style={{
              width: "90%",
              maxWidth: "500px",
              marginTop: "50px",
              marginBottom: "20px",
            }}
            alt="map"
          />
        </A>
      </Grid>
    </FadeWrapper>
  );
}

function Locations({ isChat, toggleIsChat }) {
  return (
    <>
      <Grid
        container
        justifyContent="space-evenly"
        sx={{ padding: "50px 10px" }}
      >
        <Typography
          id="contact"
          sx={{ width: "100%", textAlign: "center" }}
          variant="h4"
        >
          Our Locations
          <UnderlineT />
        </Typography>
        <Tecumseh />
        <Chatham />
      </Grid>
    </>
  );
}

export default Locations;
