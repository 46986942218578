import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import UnderlineB from "../components/UnderlineB";
import UnderlineT from "../components/UnderlineT";
import GreatLakesLogo from "../img/GreatLakesLogo.png";
import about1 from "../img/about1.png";
import tech2 from "../img/tech2.png";

function About() {
  return (
    <Box sx={{ minHeight: "100vh", padding: "150px 20px" }}>
      <Helmet>
        <title>
          About us - Great Lakes Oral & Maxillofacial Surgery Centre
        </title>
        <meta
          name="description"
          content="Great Lakes Oral & Maxillofacial Surgery Centre is a team of highly trained and experienced professionals committed to providing the highest quality of care to their patients."
        />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={GreatLakesLogo} />
        <meta
          property="og:title"
          content="About us - Great Lakes Oral & Maxillofacial Surgery Centre"
        />
        <meta
          property="og:url"
          content="http://www.greatlakessurgerycentre.com/about"
        />
        <meta
          property="og:description"
          content="Great Lakes Oral & Maxillofacial Surgery Centre is a team of highly trained and experienced professionals committed to providing the highest quality of care to their patients."
        />
        {/* End Facebook tags */}
        {/* Twitter tags */}

        {/* End Twitter tags */}
      </Helmet>
      <Grid container alignItems="center" flexDirection="column">
        <Typography variant="h4">About us</Typography>
        <UnderlineT />
        <Grid item container sx={{ marginTop: { xs: "30px", sm: "100px" } }}>
          <Grid
            item
            container
            xs={12}
            sm={6}
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box>
              <img
                style={{ maxWidth: "600px", width: "99%" }}
                src={about1}
                alt="About Us"
              />
            </Box>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={6}
            flexDirection="column"
            sx={{ padding: "10px 5%" }}
          >
            <Typography
              variant="h5"
              sx={{
                margin: { xs: "0 auto", sm: "0px" },
                marginBottom: "0px",
                marginTop: { xs: "30px", sm: "0px" },
                maxWidth: "max-content",
                width: "100%",
              }}
            >
              Our Values
              <UnderlineB />
            </Typography>
            <Typography sx={{ maxWidth: "60ch" }} color="text.medium">
              Great Lakes Oral & Maxillofacial Surgery Centre takes great pride
              in bringing their patients the latest in technologically advanced,
              comfort oriented oral and maxillofacial surgical care. Both of our
              Tecumseh and Chatham offices are independently inspected
              outpatient surgical facilities, and are equipped with modern
              specialized equipment. They are staffed with a highly trained,
              experienced and compassionate team of caring professionals.
              <br />
              <br />
              Dr. Shuren, Dr. Stapleford, Dr. Walker, and Dr. Laschuk have
              current and advanced training in the diagnosis and treatment of
              oral and maxillofacial problems from the simple to the complex.
              This involves the expertise in: the removal of difficult or
              impacted teeth (wisdom teeth), the placement of dental implants,
              the management of facial injuries and infections, the treatment of
              dental and skeletal deformity (orthognathic surgery), the surgical
              management of temporomandibular joint (TMJ) dysfunction, facial
              and jaw reconstruction procedures and care for other diseases
              (pathology) and aesthetic concerns of the oral and maxillofacial
              region and related structures.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        flexDirection="column"
        sx={{ backgroundColor: "f5f5f5" }}
      >
        <Grid item container sx={{ marginTop: { xs: "30px", sm: "100px" } }}>
          <Grid
            item
            container
            xs={12}
            sm={6}
            flexDirection="column"
            alignItems="flex-end"
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                padding: { xs: "10px 5%", sm: "0px" },
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  margin: { xs: "0 auto", sm: "0px" },
                  marginBottom: "0px",
                  marginTop: { xs: "20px", sm: "0px" },
                  maxWidth: "max-content",
                  width: "100%",
                }}
              >
                The latest Technology
                <UnderlineB />
              </Typography>
              <Typography variant="h6" sx={{ marginBottom: "10px" }}>
                CBCT (Cone Beam Computed Tomography)
              </Typography>
              <Typography sx={{ maxWidth: "60ch" }} color="text.medium">
                CBCT is a 3D medical imaging technique consisting of x-ray
                computed tomography. CBCT has become increasingly important in
                treatment planning and diagnosis in implant dentistry, symmetry
                of the condyles and morphology of sinus, surgical extractions,
                nerve mapping, to visualize erupted and non-erupted teeth and
                tooth root orientation that a conventional 2D radiograph cannot.
                A CBCT offers invaluable information when it comes to assessment
                and planning of surgical treatment. Since we have been using
                this technology it has allowed us to proceed with most surgical
                cases while having more confidence and less uncertainty about
                bone quality and quantity. This means in many cases, we are able
                to avoid bone grafting altogether where in the past we would
                have had to perform bone grafts prior to implant placement.
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginTop: "20px", marginBottom: "10px" }}
              >
                Piezoelectric Surgery in Oral and Maxillofacial Surgery{" "}
              </Typography>
              <Typography sx={{ maxWidth: "60ch" }} color="text.medium">
                The piezoelectric device or piezosurgery device was developed to
                reduce the risk of tissue and adjacent tooth damage while
                cutting of bone by way of ultrasonic vibrations and as an
                alternative to the mechanical and electrical instruments that
                are used in the conventional ways in oral surgery. Over the past
                two decades, an extensive amount of literature has shown that
                piezoelectric devices are excellent tools and that there are
                extensive indications of their use in oral and maxillofacial
                surgery. Here at Great Lakes Oral Surgery we have been using
                this device for the past ten years for combined surgical
                orthodontic treatment, nerve repositioning, bone grafting and
                surgical extractions with excellent success rates.
              </Typography>
              <Typography
                variant="h6"
                sx={{ marginTop: "20px", marginBottom: "10px" }}
              >
                CT Guided Cases
              </Typography>
              <Typography sx={{ maxWidth: "60ch" }} color="text.medium">
                CT Guided Cases Implant surgery should be performed under the
                safest and most accurate conditions possible. In our office we
                are now able to provide our patients with custom made drill
                guides which provide a seamless link with implant planning and
                the actual surgical treatment. This guide helps to guarantee
                predictable implant treatment as well as dramatically increasing
                case success while reducing the potential for multiple surgeries
                in a case where a patient may have required a bone graft
                otherwise.
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            container
            alignItems="center"
            xs={12}
            sm={6}
            sx={{ padding: "10px 5%" }}
          >
            <Box>
              <img
                style={{ maxWidth: "600px", width: "99%", height: "auto" }}
                src={tech2}
                alt="About Us"
              />
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

export default About;
