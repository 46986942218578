import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { Helmet } from "react-helmet-async";
import UnderlineT from "../components/UnderlineT";
import GreatLakesLogo from "../img/GreatLakesLogo.png";
import laschuk1 from "../img/laschuk1.png";
import shuren1 from "../img/shuren1.png";
import stapleford1 from "../img/stapleford1.png";
import walker1 from "../img/walker1.png";
import Doc from "./Doc";

function Doctors() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        justifyContent: "center",
        padding: "150px 0px",
      }}
    >
      <Helmet>
        <title>Doctors - Great Lakes Oral & Maxillofacial Surgery Centre</title>
        <meta
          name="description"
          content="Dr. Shuren, Dr. Stapleford, Dr. Walker, and Dr. Laschuk have current and advanced training in the diagnosis and treatment of oral and maxillofacial problems from the simple to the complex."
        />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={GreatLakesLogo} />
        <meta
          property="og:title"
          content="Doctors - Great Lakes Oral & Maxillofacial Surgery Centre"
        />
        <meta
          property="og:url"
          content="http://www.greatlakessurgerycentre.com/doctors"
        />
        <meta
          property="og:description"
          content="Dr. Shuren, Dr. Stapleford, Dr. Walker, and Dr. Laschuk have current and advanced training in the diagnosis and treatment of oral and maxillofacial problems from the simple to the complex."
        />
        {/* End Facebook tags */}
        {/* Twitter tags */}

        {/* End Twitter tags */}
      </Helmet>
      <Grid container flexDirection="column" alignItems="center">
        <Typography
          variant="h4"
          color="text.primary"
          sx={{ marginBottom: { xs: "30px", sm: "70px" } }}
        >
          Meet Our Doctors
          <UnderlineT />
        </Typography>

        <Doc
          doctor="Dr. Mark Shuren"
          degrees="HONS.B.SC., DDS, FRCD(C), Dip. ABOMS"
          intro="Dr. Shuren grew up in Windsor, Ontario. He graduated from the University of Western Ontario in 1986 with a Bachelors of Science Degree. He completed an Honours Degree at the University of Windsor in 1987. Dr. Shuren attended McGill University School of Dentistry and graduated in 1991."
          image={shuren1}
          education={[
            "BSc - University of Western Ontario",
            "Honours Degree - University of Windsor",
            "D.D.S. - McGill University",
            "Residency/Training - Medical Center of Delaware",
          ]}
          scope={[
            "Facial trauma",
            "Fractures and jaw reconstruction",
            "Orthognathic surgery using state of the art computer-aided planning software",
            "CAT scan guided implants surgery",
            "Dental implants",
            "Bone grafting",
            "Oral pathology",
            "Temporomandibular joint surgery",
            "Wisdom tooth removal",
            "General extractions with local and general anesthetic",
          ]}
          specialty="Oral and Maxillofacial Surgery"
        >
          <Typography>
            In 1992, he served in a General Practice Residency internship at The
            Medical Center of Delaware in Delaware, USA. Dr. Shuren completed a
            four year Oral and Maxillofacial Surgery residency at the Medical
            Center of Delaware in 1996. He completed his Fellowship in Oral and
            Maxillofacial Surgery from the Royal College of Dentists of Canada
            (FRCD) this same year.
            <br />
            <br />
            In 1996, Dr. Shuren, his wife Pam, and their three children returned
            to Canada to practice in his hometown. Dr. Shuren has been in
            private practice in Windsor since 1996. He has initiated Advanced
            Cardio Life Support courses in Essex County and became the first
            director and instructor of ACLS in the community.
            <br />
            <br />
            In 1999, Dr. Shuren passed his American Boards in the United States
            and was awarded Diplomat status by the American Board of Oral and
            Maxillofacial Surgery (Board Certified). He was re-certified again
            in 2010.
            <br />
            <br />
            In September of 2009, Dr. Shuren built a new practice located at
            1825 Manning Road in Tecumseh, Ontario to better serve his patients’
            needs from a comfort and technologically advanced approach.Dr.
            Shuren is a National Board Examiner in the speciality of Oral and
            Maxillofacial Surgery to certify new professionals in the field.
            <br />
            <br />
            Dr. Shuren has had extensive training in all areas of oral and
            maxillofacial surgery. He operates at his office and at both local
            hospitals – Windsor Regional Hospital Ouellete & Metropolitan
            Campus. Dr. Shuren has been appointed as an Adjunct Clinical
            Professor and Preceptor to Western University Schulich School of
            Medicine & Dentistry, London and Windsor campus, in the training
            program for dual degree (M.D. and D.D.S.) Oral and Maxillofacial
            Surgery.
          </Typography>
        </Doc>
        <Doc
          doctor="Dr. Richard Stapleford"
          degrees="DDS, FRCD(C), Dip. ABOMS"
          intro=" Dr. Stapleford joined the Great Lakes Oral and Maxillofacial Surgery Centre in January 2011 after thirty years of specialty practice in Windsor and Essex County. He currently serves as Chief of Oral and Maxillofacial Surgery and Dentistry at Windsor Regional Hospitals. Dr. Stapleford is an Adjunct Clinical Professor in the Department of Surgery at the UWO Schulich School of Medicine and Dentistry as well as a consultant at both The Windsor Regional Cancer Treatment Centre and The John McGivney Children’s Centre Maxillofacial Cleft Palate Clinic."
          image={stapleford1}
          education={[
            "BSc - University of Western Ontario",
            "D.D.S. - University of Western Ontario",
            "Residency/Training - UofT, Henry Ford Hospital ",
          ]}
          scope={[
            "Maxillofacial injuries and fractures",
            "Reconstructive and jaw surgery",
            "Temporomandibular joint Surgery",
            "Benign and malignant oral pathology",
            "Dental implants ",
            "Bone grafting",
            "Wisdom tooth removal",
            "Dental extractions using local and general anesthesia",
          ]}
          specialty="Oral and Maxillofacial Surgery"
        >
          <Typography>
            Dr. Stapleford received his undergraduate and doctoral education at
            the University of Western Ontario followed by postgraduate training
            at the University of Toronto and University of Western Ontario. He
            trained in oral and maxillofacial surgery in the Department of
            Surgery at the Henry Ford Hospital, Detroit Michigan and practiced
            and taught at that institution until 1981 when he returned to
            Windsor while continuing to teach at the University of Detroit
            Mercy.
            <br />
            <br />
            Dr. Stapleford has successfully completed the specialty examinations
            of both the American Board of Oral and Maxillofacial Surgery and the
            Royal College of Dental Surgeons of Canada and has served as an
            advisor and examiner for both specialty organizations. Dr.
            Stapleford has been appointed as an Adjunct Clinical Professor and
            Preceptor to Western University Schulich School of Medicine &
            Dentistry, London and Windsor campus, in the training program for
            dual degree (M.D. and D.D.S.) Oral and Maxillofacial Surgery.
            Further, Dr. Stapleford is a Preceptor and Lecturer in the
            Department of Surgery of Schulich, Windsor, in Facial Trauma and
            applied Surgical Anatomy. In addition, he has completed the
            examination for diplomate status with the National Dental Board of
            Anesthesiology (U.S.A.).
            <br />
            <br />
            His teaching awards include the Ontario Society of Oral and
            Maxillofacial Surgeons Award of Merit, the University of Detroit
            Award for Excellence in Teaching and Patient Care, The Schulich
            School of Medicine and Dentistry Teaching Recognition Award and the
            American Board of Oral and Maxillofacial Surgery Examination
            Committee Award.
            <br />
            <br />
            Most recently, Dr. Stapleford has been appointed to the school of
            Graduate studies of Western University as a Thesis Examiner.
            <br />
            <br />
            Consistent with the scope of practice offered at Great Lakes Oral
            and Maxillofacial Surgery Centre, Dr. Stapleford maintains strong
            interest in the treatment of maxillofacial injuries and fractures,
            reconstructive and jaw surgery; including the temporomandibular
            joint, benign and malignant oral pathology, dental implants and bone
            grafting as well as wisdom teeth and dental extractions using local
            and general anesthesia.
            <br />
            <br />
            Outside of his professional activity Dr. Stapleford devotes his time
            to his wife, Diana and their five children and six grandchildren.
          </Typography>
        </Doc>
        <Doc
          doctor="Dr. Cameron Walker"
          degrees="DDS, FRCD(C), Dip. ABOMS"
          image={walker1}
          intro="Dr. Cameron Walker was born and raised in Amherstburg, Ontario. He attended the University
of Windsor, and then the University of Detroit Mercy for dental school. He then completed a
four-year residency in Oral and Maxillofacial Surgery in Chicago, Illinois at Loyola University
Medical Center. Dr. Walker returned to Windsor to practice in 2017."
          education={[
            "BSc - University of Windsor",
            "D.D.S. - University of Detroit Mercy",
            "Residency/Training -  Loyola University Medical Center, Chicago IL.",
          ]}
          scope={[
            "Facial trauma and reconstruction",
            "Pathology",
            "Dental implants",
            "CT guided surgery",
            "Bone grafting",
            "Wisdom tooth removal",
            "Dental extractions under sedation and general anesthesia",
            "All-on-4 implant treatment",
            "Orthognathic surgery",
            "Management of TMJ disorders",
          ]}
          specialty="Oral and Maxillofacial Surgery"
        >
          <Typography variant="body1">
            Dr. Walker is board certified in both Canada and the United States.
            He is a fellow of the Royal College of Dentists of Canada, and a
            diplomate of the American Board of Oral and Maxillofacial Surgery.
            He is also a member of both the Canadian and American Associations
            of Oral and Maxillofacial Surgery. Dr. Walker has been appointed as
            an Adjunct Clinical Professor to Western University Schulich School
            of Medicine &amp; Dentistry, and he operates at Windsor Regional
            Hospital&#39;s Ouellette and Metropolitan campuses.
            <br />
            <br />
            Dr. Walker’s scope of practice includes pathology, facial trauma and
            reconstruction, dental implantology and bone grafting techniques. He
            has a special interest in full arch rehabilitation with dental
            implants, and often lectures on the topic.
            <br />
            <br />
            When he is not operating, Dr. Walker enjoys playing tennis, golf,
            hockey, traveling and spending time with his friends and growing
            family.
          </Typography>
        </Doc>
        <Doc
          // intro="Dr. Laschuk, a Windsor native, the newest member at the Great Lakes Oral and Maxillofacial Surgery Centre."
          intro="Michael Laschuk attended the University of Toronto where he
            completed his doctorate of dental surgery and a surgical internship.
            Dr. Laschuk continued his training in Toronto, completing a
            residency in Oral and Maxillofacial Surgery in association with a
            Masters of Science. After completing his surgical residency, he
            returned to Windsor, his hometown, to provide care to the local
            community."
          doctor="Dr. Michael Laschuk"
          degrees="DDS, MSc., FRCD(C), Dip. ABOMS"
          image={laschuk1}
          education={[
            "DDS - University of Toronto",
            "Surgical Internship - University of Toronto",
            "Residency/Training - University of Toronto",
            "M.Sc. - University of Toronto",
          ]}
          scope={[
            "Orthognathic surgery",
            "Maxillofacial trauma and reconstruction",
            "Management of temporomandibular joint pathologies and disorders",
            "Oral and maxillofacial pathologies",
            "Dental implants",
            "Bone grafting",
            "Wisdom tooth extraction under local or general anesthesia",
          ]}
          specialty="Oral and Maxillofacial Surgery"
        >
          {/* <Typography>
            Dr. Laschuk, a Windsor native, completed his OMFS residency at the
            University of Toronto. Prior to this, he completed three years of
            undergraduate training studying Chemistry at the University of
            Windsor. He then went on to complete his dental school training in
            2013 at the University of Toronto. In 2015, he returned to Toronto
            and completed a one year Oral and Maxillofacial Surgery Internship.
            <br />
            <br />
            Between his dental school and specialty training Dr. Laschuk worked
            as a general dentist for two years providing dental care in
            underserved areas across Canada including in Yellowknife, NWT and in
            many remote native reserves across northwestern Ontario. During his
            final year in residency, Dr. Laschuk joined a surgical educational
            mission to Addis Ababa, Ethiopia.
            <br />
            <br />
            His scope of practice includes: orthognathic surgery, maxillofacial
            trauma and reconstruction, management of temporomandibular joint
            pathologies and disorders, oral and maxillofacial pathologies, bone
            grafting, dental implants, and wisdom tooth extraction under local
            or general anesthesia.
          </Typography> */}
          <Typography>
            Dr. Laschuk is a current fellow of the Royal College of Canada for
            Oral and Maxillofacial surgery, and a board-certified Oral and
            Maxillofacial Surgeon in the United States. He is currently on staff
            at Windsor Regional Hospital and is an adjunct clinical professor
            for the Western University Oral and Maxillofacial Surgery residency
            program.
            <br />
            <br />
            In an office setting, Dr. Laschuk is able to provide services
            including complex exodontia, intravenous sedation, bone grafting,
            and virtually guided implant placement. Dr. Laschuk also provides
            hospital care to his patients including orthognathic surgery,
            complex facial reconstruction, surgical management of head and neck
            pathology, and temporomandibular joint reconstruction.
            <br />
            <br />
            Dr. Laschuk plays an active role in providing care to patients at
            the John McGivney Cleft Clinic as a staff surgeon. He has provided
            care in underserved communities across northern Ontario and on a
            maxillofacial mission trip to Ethiopia.
            <br />
            <br />
            Dr. Laschuk holds an executive position with the Ontario Society of
            Oral and Maxillofacial Surgery, where he acts as the vice-president.
          </Typography>
        </Doc>
      </Grid>
    </Box>
  );
}

export default Doctors;
