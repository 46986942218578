import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionAccordion from "../components/QuestionAccordion";
import UnderlineB from "../components/UnderlineB";

function GeneralInstructions() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          General Instructions
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography sx={{ marginBottom: "50px" }}>
        Do not disturb the wound. Avoid rinsing, spitting, or touching the wound
        on the day of surgery.
      </Typography>
      <QuestionAccordion title="Bleeding">
        Some bleeding or redness in the saliva is normal for 24 hours. Excessive
        bleeding which results in your mouth filling rapidly with blood can
        frequently be controlled by biting with pressure on a gauze pad placed
        directly on the bleeding wound for 30 minutes.{" "}
        <span style={{ fontWeight: "bold" }}>
          If bleeding continues, please call the office for further
          instructions.{" "}
        </span>
      </QuestionAccordion>
      <QuestionAccordion title="Swelling">
        Swelling is a normal occurrence after surgery. To minimize swelling,
        apply an ice bag or a plastic bag or towel filled with ice cubes on the
        cheek/lip in the area of surgery. Apply the ice intermittently for
        20-30-minute intervals as much as possible for the first 48 hours.
      </QuestionAccordion>
      <QuestionAccordion title="Diet">
        Drink plenty of fluids. Avoid hot liquids or food. Soft food and liquids
        should be eaten on the day of surgery. Return to a normal diet as soon
        as tolerated unless otherwise directed.
      </QuestionAccordion>
      <QuestionAccordion title="Pain">
        You should begin taking pain medication before you feel the local
        anesthetic wearing off. For mild to moderate pain, Tylenol or Extra
        Strength can be taken as directed on bottle. Tylenol may be taken every
        4-6 hours. Ibuprofen (Advil, Motrin) may be taken instead of Tylenol
        assuming no allergies to these medications. For severe pain, the
        prescribed medication should be taken as directed. Do not take any of
        the above medication if you are allergic, or have been instruction by
        your doctor not to take it.
      </QuestionAccordion>
      <QuestionAccordion title="Oral Hygiene">
        Good oral hygiene is essential to good healing. Warm salt water rinses
        should be used at least 4-5 times a day especially after meals. Use the
        peridex script as prescribed, if given. Brushing your teeth is no
        problem. Be gentle initially with brushing the surgical areas.
      </QuestionAccordion>
      <QuestionAccordion title="Activity">
        Keep physical activities to a minimum immediately following surgery. If
        you are considering exercise, throbbing or bleeding may occur. If this
        occurs, you should discontinue exercising. Be aware that your normal
        nourishment intake is reduced. Exercise may weaken you. If you get light
        headed, stop exercising.
      </QuestionAccordion>
      <QuestionAccordion title="Post IV Sedation">
        After IV sedation, the patient is required to have a responsible adult
        to accompany them for a minimum of 18 hours or longer if drowsiness or
        dizziness persists. NO exceptions!! The patient should not drive a
        vehicle, operate machinery, make important financial/life decisions,
        consume alcohol or other drugs with sedative properties for 24 hours
        following the anesthetic.
      </QuestionAccordion>
      <Typography
        sx={{
          margin: "0 auto",
          marginTop: "20px",
          textAlign: "center",
          maxWidth: "70%",
        }}
      >
        If you have any questions or concerns don’t hesitate to call the office
        to speak with a nurse. Tecumseh{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-979-7227"
        >
          (519)-979-7227
        </a>{" "}
        or Chatham{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-397-1177"
        >
          (519)-397-1177
        </a>{" "}
      </Typography>
    </Grid>
  );
}

export default GeneralInstructions;
