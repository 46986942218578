import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionAccordion from "../components/QuestionAccordion";
import UnderlineB from "../components/UnderlineB";

function CorrectiveJaw() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          Orthognathic Jaw Surgery (Corrective Jaw Surgery)
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography sx={{ marginBottom: "50px" }}>
        Orthognathic surgery is needed when the jaws, and the teeth within them
        don’t seem to fit together correctly. Teeth are straightened with
        orthodontics and corrective jaw surgery repositions misaligned jaws.
        This not only improves facial appearance, but more importantly ensures
        that teeth meet correctly and function properly against each other.
      </Typography>

      <QuestionAccordion title="Who Needs Orthognathic Surgery?">
        People who can benefit from orthognathic surgery include those with an
        improper bite or jaws that are positioned incorrectly. Jaw growth is a
        gradual process and in some instances, the upper and lower jaws may grow
        at different rates. The result can be a host of problems that can affect
        chewing function, speech, long-term oral health and appearance. Injury
        to the jaw and birth defects can also affect jaw alignment. Orthodontics
        alone can correct bite problems when only the teeth are involved.
        Orthognathic surgery may be required for the jaws when repositioning is
        necessary.
      </QuestionAccordion>
      <QuestionAccordion title="Difficulty in the following areas should be evaluated:">
        <ul>
          <li>difficulty in chewing, biting or swallowing</li>
          <li>speech problems</li>
          <li>chronic jaw or TMJ pain</li>
          <li>open bite (teeth not in contact while biting together)</li>
          <li>protruding jaw</li>
          <li>breathing problems</li>
          <li>facial deformity and/or deformity</li>
        </ul>
        Any of these symptoms can exist at birth, be acquired after birth as a
        result of hereditary or environmental influences or as a result of
        trauma to the face. Before any treatment begins, a consultation will be
        held to perform a complete examination with x-rays. During the
        pre-treatment consultation process, feel free to ask any questions that
        you have regarding your treatment. When you are fully informed about the
        aspects of your care, you and your dental team will make the decision to
        proceed with treatment together. If you are a candidate for Corrective
        Jaw Surgery, your surgeon will work closely with your dentist and
        orthodontist during your treatment. The actual surgery can move your
        teeth and jaws into a new position that results in a more attractive,
        functional and healthy dental-facial relationship.
      </QuestionAccordion>
    </Grid>
  );
}

export default CorrectiveJaw;
