import { Grid, Typography } from "@mui/material";
import React from "react";
import UnderlineB from "../components/UnderlineB";

function OrthodonticSurgery() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          Surgery for Orthodontics
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography sx={{ marginBottom: "50px" }}>
        Many patients are referred to our practice by area orthodontists. Their
        orthodontists will request we perform a surgical procedure that will
        then permit the orthodontists to properly align the patients teeth.
        Sometimes, this is as simple as removing some retained primary (“baby”)
        teeth, removing some permanent premolars to create space if there is too
        much crowding, or removing wisdom teeth after the other teeth are
        orthodontically aligned so that the developing wisdom teeth don’t
        adversely affect the teeth in front of them. In more involved cases,
        some permanent teeth may fail to erupt at the expected time. If this
        occurs, the orthodontist will request surgical exposure of an impacted
        tooth. This consists of removing the bone and gum tissue covering a
        tooth embedded in the bone so that an orthodontic bracket may be placed.
        This will provide the orthodontist access to that embedded tooth so that
        they can apply a force to it and bring it to it’s proper position.
        <br />
        <br />
        In some situations there is a missing tooth in a very important area of
        the mouth. Certain cases may dictate removing an unerupted tooth in a
        less important area and placing it surgically into this very important
        area for it to develop. This is called procedure is called
        autotransplantation. In other instances, certain situations will dictate
        the use of skeletal anchorage. This consists of surgically inserting
        small titanium screws into either the upper or lower jaw bones. These
        anchorage screws will protrude somewhat through the gum tissue. Your
        orthodontists will then apply a force to a tooth by stretching an
        elastic or applying a spring between the bracket on the tooth and the
        top part of the anchorage screw. Once the desired tooth movement is
        achieved, the anchorage screws are simply removed. Finally, simple soft
        tissue procedures like frenectomies are sometimes advised by the
        orthodontists. This entails removing the frenum, or muscle attachment
        and the thin layer of gum tissue covering the frenum, from under the
        under lip. This prevents relapse of a diastema (space between the two
        front teeth) once it has been orthodontically closed.
      </Typography>
    </Grid>
  );
}

export default OrthodonticSurgery;
