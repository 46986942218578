import { Box, Grid, Typography } from "@mui/material";
import { animated, config, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import styles from "../css/offers.module.css";
import reviewbg1 from "../img/reviewbg1.jpg";
import Review from "./Review";

function Reviews() {
  const [openReviews, toggleReviews] = useState(false);

  const props = useSpring({
    width: openReviews ? "50px" : "0px",
    backgroundColor: openReviews ? "white" : "transparent",
    config: config.wobbly,
  });

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        sx={{
          marginTop: "100px",
          backgroundImage: `url(${reviewbg1})`,
          backgroundPosition: { xs: "center", md: "0 0" },
          width: "100vw",
          minHeight: "1000px",
          backgroundSize: "cover",
        }}
      >
        <Grid item container justifyContent="center">
          <Grid item>
            <Typography
              variant="h4"
              color="text.white"
              sx={{
                marginTop: { xs: "100px", md: "250px" },
              }}
            >
              Our Reviews
            </Typography>
            <animated.div className={styles.fill} style={props}></animated.div>
            <Waypoint bottomOffset="20%" onEnter={() => toggleReviews(true)} />
            <Box sx={{ marginBottom: "40px" }}> </Box>
          </Grid>
        </Grid>
        <Review
          link="https://g.co/kgs/eNrgu8"
          topmd="0px"
          toplg="-100px"
          topxs="0px"
          name="Melissa Broderick"
          text=' "My 8 year old daughter recently had a procedure done with Dr. Shuren. I
        appreciate the wonderful nurses providing me with all the information
        beforehand so we knew exactly what to expect going in. Dr. Shuren did a
        wonderful job and the procedure was done quickly. The nurses were so
        warm and helpful and made my daughter feel comfortable. They gave me
        step by step instructions on how to care for her post procedure as well.
        I can’t thank Dr. Shuren and all the nurses enough for the kind, warm
        and efficient care they provided to my daughter. I highly recommend
        them!!"'
        />
        <Review
          topmd="0px"
          topxs="10px"
          toplg="0px"
          link="https://g.co/kgs/QkmyG4"
          name="David Williams"
          text=' "Last week You accepted my disabled wife for emergency extractions. Dr. Walker and his entire staff were extremely kind and efficient in the manner my wife was treated. It is now 3 days post surgery and my wife is recovering fine. I want to thank Dr. Walker and the entire staff for the care you provided my wife. What a wonderful organization you have!"'
        />
        <Review
          link="https://g.co/kgs/6oqYJZ"
          topmd="0px"
          toplg="-170px"
          topxs="10px"
          name="Renee Hamelin"
          text={
            '"My daughter has had 2 separate procedures done here, and the staff have been nothing short of amazing each time! They are patient, friendly, knowledgeable, and  go above and beyond to ensure that you are aware of what is happening, and comfortable.  My daughter was very well taken care of, and didn\'t even require any extra medication for pain management after both of her procedures. She was back to her normal, cheerful self in no time!! I highly recommend Great Lakes Oral!!"'
          }
        />
      </Grid>
    </>
  );
}

export default Reviews;
