import { Box, Grid, Typography, styled } from "@mui/material";
import { animated, config, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import styles from "../css/offers.module.css";

import { Link } from "react-router-dom";
import { Waypoint } from "react-waypoint";
import accolades from "../img/accolades.jpg";
import tooth1 from "../img/tooth1.png";
import tooth2 from "../img/tooth2.png";
import tooth3 from "../img/tooth3.png";
import tooth4 from "../img/tooth4.png";
import tooth5 from "../img/tooth5.png";
import tooth6 from "../img/tooth6.png";
import tooth7 from "../img/tooth7.png";
import tooth8 from "../img/tooth8.png";
import tooth9 from "../img/tooth9.png";

const OfferBox = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: theme.palette.text.primary,
  "&:hover": {
    transition: "all .2s ease-in-out",
    transform: "scale(1.1)",
    "& img": {
      boxShadow: "0px 0px 7px rgb(0,0,0,0.2)",
      borderRadius: "100%",
    },
  },
}));

function Offering(props) {
  return (
    <OfferBox
      to={props.to}
      sx={{
        maxWidth: "400px",
        display: "flex",
        alignItems: "center",
        margin: "20px",
      }}
    >
      <img
        src={props.image}
        alt={props.title}
        style={{
          height: "70px",
          marginRight: "20px",
        }}
      />
      <Box sx={{ width: "200px" }}>
        <Typography variant="h6">{props.title}</Typography>
        <Typography sx={{ fontSize: "14px" }}>{props.text}</Typography>
      </Box>
    </OfferBox>
  );
}

function Accolade(props) {
  const [openAccolades, toggleAccolades] = useState(false);

  const { numberSales } = useSpring({
    reset: false,
    from: { numberSales: 0 },
    numberSales: openAccolades ? props.number : 0,
    delay: props.delay,
    config: config.molasses,
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Typography
        sx={{ marginBottom: "10px", display: "flex-inline" }}
        color="text.white"
        variant="h4"
      >
        <animated.span>{numberSales.to((n) => n.toFixed(0))}</animated.span>
        {props.plus ? " +" : null}
      </Typography>
      <Typography variant="h6" color="text.white">
        {props.label}
      </Typography>
      <Waypoint bottomOffset="20%" onEnter={() => toggleAccolades(true)} />
    </Box>
  );
}

function Offer() {
  const [openOffer, toggleOffer] = useState(false);

  const props = useSpring({
    width: openOffer ? "50px" : "0px",
    backgroundColor: openOffer ? "#3A959B" : "white",
    delay: 200,
    config: config.wobbly,
  });

  return (
    <>
      <Grid
        sx={{ paddingTop: "40px", maxWidth: "1400px", margin: "0 auto" }}
        container
        alignItems="center"
        flexDirection="column"
      >
        <Typography sx={{ marginTop: "50px" }} variant="h4">
          We Offer
        </Typography>
        <Waypoint bottomOffset="20%" onEnter={() => toggleOffer(true)} />
        <animated.div className={styles.fill} style={props}></animated.div>

        <Grid item container justifyContent="center">
          <Offering
            image={tooth1}
            text="High quality titanium implants for tooth replacement"
            title="Dental Implants"
            to={"surgery/dental-implants"}
          />
          <Offering
            image={tooth2}
            text="We offer pain free wisdom tooth extraction"
            title="Wisdom Teeth"
            to={"surgery/wisdom-teeth"}
          />
          <Offering
            image={tooth3}
            text="We offer pain free general tooth extraction"
            title="Tooth Extraction"
            to={"surgery/tooth-extraction"}
          />
          <Offering
            image={tooth4}
            text="Replace, repair, grow bone in areas that need support"
            title="Bone Grafting"
            to={"surgery/bone-grafting"}
          />
          <Offering
            image={tooth5}
            text="Correction of dental misalignment for functional harmony"
            title="Orthodontics Surgery"
            to={"surgery/orthodontic-surgery"}
          />
          <Offering
            image={tooth6}
            text="Treat facial trauma to improve long term function and appearance"
            title="Facial Trauma"
            to={"surgery/facial-trauma"}
          />
          <Offering
            image={tooth7}
            text="Study and diagnosis of conditions affecting the mouth and jaw"
            title="Oral Pathology"
            to={"surgery/oral-pathology"}
          />
          <Offering
            image={tooth8}
            text="Surgery to correct misaligned jaws for improved function and appearance"
            title="Jaw Corrective"
            to={"surgery/jaw-corrective"}
          />
          <Offering
            image={tooth9}
            text="Relieve Temporomandibular disorders related to your complex jaw joint"
            title="TMJ Disorder"
            to={"surgery/tmj-disorder"}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          marginTop: "70px",
          marginBottom: "70px",
          backgroundImage: `url(${accolades})`,
          backgroundSize: "cover",
          width: "100vw",
          minHeight: "300px",
        }}
      >
        <Grid
          item
          container
          xs={12}
          sm={4}
          sx={{
            height: "100%",
            minHeight: "150px",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "&:after": {
              content: "''",
              width: { xs: "50%", sm: "100%" },
              position: "absolute",
              top: 0,
              bottom: 0,
              left: { xs: "25%", sm: "0%" },
              right: { xs: "25%", sm: "0%" },
              borderRight: {
                xs: "none",
                sm: "1px solid rgb(255,255,255,0.4)",
              },
              borderBottom: {
                xs: "1px solid rgb(255,255,255,0.4)",
                sm: "none",
              },
            },
          }}
        >
          <Accolade label="Doctors" number="6M+" delay={0} />
        </Grid>
        <Grid
          item
          container
          direction="column"
          xs={12}
          sm={4}
          sx={{
            height: "100%",
            minHeight: "150px",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
            "&:after": {
              content: "''",
              width: { xs: "50%", sm: "100%" },
              position: "absolute",
              top: 0,
              bottom: 0,
              left: { xs: "25%", sm: "0%" },
              right: { xs: "25%", sm: "0%" },
              borderRight: {
                xs: "none",
                sm: "1px solid rgb(255,255,255,0.4)",
              },
              borderBottom: {
                xs: "1px solid rgb(255,255,255,0.4)",
                sm: "none",
              },
            },
          }}
        >
          <Accolade label="Amazing Staff" number={23} delay={400} />
        </Grid>
        <Grid
          item
          container
          xs={12}
          sm={4}
          sx={{
            height: "100%",
            minHeight: "150px",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <Accolade label="Years Experience" number={30} delay={800} plus />
        </Grid>
      </Grid>
    </>
  );
}

export default Offer;
