import React from "react";
import { Helmet } from "react-helmet-async";
import GreatLakesLogo from "../img/GreatLakesLogo.png";
import Main from "./Main";

function Home() {
  return (
    <>
      <Helmet>
        <title>Home - Great Lakes Oral & Maxillofacial Surgery Centre</title>
        <meta
          name="description"
          content="Get advanced and compassionate oral and maxillofacial surgical care at Great Lakes Oral & Maxillofacial Surgery Centre. Our experienced team uses the latest technology and techniques to provide comfortable treatment options. Contact us today."
        />
        {/* End standard metadata tags */}
        {/* Facebook tags */}
        <meta property="og:type" content="website" />
        <meta property="og:image" content={GreatLakesLogo} />
        <meta
          property="og:title"
          content="Great Lakes Oral & Maxillofacial Surgery Centre"
        />
        <meta
          property="og:url"
          content="http://www.greatlakessurgerycentre.com"
        />
        <meta
          property="og:description"
          content="Get advanced and compassionate oral and maxillofacial surgical care at Great Lakes Oral & Maxillofacial Surgery Centre."
        />
        {/* End Facebook tags */}
        {/* Twitter tags */}

        {/* End Twitter tags */}
      </Helmet>
      <Main />
    </>
  );
}

export default Home;
