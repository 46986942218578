import { Box } from "@mui/material";
import React from "react";

function Payment() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100vw",
        padding: "150px 0px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Online Payments Coming Soon...
    </Box>
  );
}

export default Payment;
