import { animated, config, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import styles from "../css/offers.module.css";

function UnderlineB(props) {
  const [open, toggleOpen] = useState(false);

  const spring = useSpring({
    width: open ? props.width : "0%",
    delay: 200,
    config: config.wobbly,
  });
  return (
    <>
      <Waypoint bottomOffset="20%" onEnter={() => toggleOpen(true)} />
      <animated.div className={styles.fillb} style={spring}></animated.div>
    </>
  );
}

UnderlineB.defaultProps = {
  width: "70%",
};

export default UnderlineB;
