import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import { Grid, Slide, useScrollTrigger } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import GreatLakesLogo from "../img/GreatLakesLogo.png";
import FadeWrapper from "./FadeWrapper";

const Img = styled("img")({});

const CustomNavLink = styled(NavLink)`
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #222222;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
  }
`;
const Dropdown = styled(NavLink)`
  text-decoration: none;
  position: relative;
  font-family: "Montserrat", sans-serif;
`;
const ContactButton = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  border: "1px solid #222222",
  color: "#222222",
  width: "120px",
  height: "40px",
  backgroundImage: "linear-gradient(#222222, #222222)",
  marginLeft: "50px",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "rgb(255,255,255)",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});

const drawerWidth = 240;
const navItems = ["Surgery", "Doctors", "Staff"];

function BasicMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div style={{ zIndex: "999999999" }}>
      <Dropdown
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        onMouseOver={handleClick}
        sx={{
          color: "text.primary",
          m: { xs: 1, md: 2, lg: 2.5 },
          fontSize: { md: "14px", lg: "16px" },
          alignItems: "center",
          display: "flex",
          "&:hover": {
            cursor: "pointer !important",
          },
        }}
      >
        Resources <ExpandMoreIcon />
      </Dropdown>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        disableAutoFocusItem
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        MenuListProps={{
          onMouseLeave: handleClose,
          "aria-labelledby": "basic-button",
        }}
        sx={{
          zIndex: "999999999",
          "&:hover": { cursor: "pointer" },
        }}
      >
        <MenuItem sx={{}} onClick={handleClose}>
          <Link
            to="downloads"
            style={{ textDecoration: "none", color: "#222222" }}
          >
            Download Forms
          </Link>
        </MenuItem>
        <MenuItem sx={{}} onClick={handleClose}>
          <Link
            to="payment"
            style={{ textDecoration: "none", color: "#222222" }}
          >
            Online Payment
          </Link>
        </MenuItem>
        <MenuItem sx={{}} onClick={handleClose}>
          <Link
            to="https://v3.expressregistration.ca/prod/view.php?id=52423 "
            style={{ textDecoration: "none", color: "#222222" }}
          >
            Patient Referral
          </Link>
        </MenuItem>
      </Menu>
    </div>
  );
}

function Navbar(props) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [show, setShow] = React.useState(false);

  const containerRef = React.useRef(null);

  React.useEffect(() => {
    setShow(true);
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
    console.log("clicked");
  };
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  let activeStyle = {
    color: "#222222",
    textDecoration: "underline",
    textDecorationThickness: "2px",
    textUnderlineOffset: "4.5px",
  };
  let inactive = {
    textDecoration: "none",
    color: "#222222",
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Img sx={{ maxWidth: "200px", width: "90%" }} src={GreatLakesLogo} />
      <Divider />
      <List>
        <NavLink
          to={"/"}
          style={({ isActive }) => (isActive ? activeStyle : inactive)}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Home" />
            </ListItemButton>
          </ListItem>
        </NavLink>
        {navItems.map((item) => (
          <NavLink
            key={item}
            to={item}
            style={({ isActive }) => (isActive ? activeStyle : inactive)}
          >
            <ListItem disablePadding>
              <ListItemButton sx={{ textAlign: "center" }}>
                <ListItemText primary={item} />
              </ListItemButton>
            </ListItem>
          </NavLink>
        ))}
        <NavLink
          to={"/after-surgery"}
          style={({ isActive }) => (isActive ? activeStyle : inactive)}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="After Surgery" />
            </ListItemButton>
          </ListItem>
        </NavLink>

        <NavLink
          to={"/about"}
          style={({ isActive }) => (isActive ? activeStyle : inactive)}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="About" />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          to={"/downloads"}
          style={({ isActive }) => (isActive ? activeStyle : inactive)}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Download Forms" />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          to={"/payment"}
          style={({ isActive }) => (isActive ? activeStyle : inactive)}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Online payment" />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <NavLink
          to={"/referral"}
          style={({ isActive }) => (isActive ? activeStyle : inactive)}
        >
          <ListItem disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary="Patient Referral" />
            </ListItemButton>
          </ListItem>
        </NavLink>
        <ListItem disablePadding>
          <ListItemButton
            sx={{
              justifyContent: "center",
              margin: "0px",
            }}
            onClick={() => handleDrawerToggle()}
          >
            <HashLink
              style={{ textDecoration: "none", color: "rgb(34,34,34)" }}
              to="/#contact"
            >
              <ListItemText primary="Contact" />
            </HashLink>
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      {/* <Grow
        style={{ transformOrigin: "top center 0" }}
        direction="down"
        in={show}
        timeout={3000}
        mountOnEnter
        unmountOnExit
      > */}
      <AppBar
        sx={{
          zIndex: "9999",
          transition: "500ms",
          minHeight: "100px",
          justifyContent: "center",
          backdropFilter: "blur(10px)",
          backgroundColor:
            trigger || props.trig2 > 0
              ? "rgb(255,255,255,0.7)"
              : "rgb(34, 34, 34, 0.0)",
          // background: trigger ? "rgb(0,0,0,0.7)" : "rgb(34, 34, 34,0.0)",
        }}
        elevation={trigger ? 4 : 0}
        component="nav"
      >
        <FadeWrapper duration={2000}>
          <Toolbar ref={containerRef} sx={{ overflow: "hidden" }}>
            <Slide
              timeout={1000}
              in={show}
              direction="up"
              container={containerRef.current}
            >
              <Grid
                sx={{
                  padding: { xs: "0px 10px", lg: "0px 20px" },
                }}
                container
                alignItems="center"
                justifyContent="space-around"
              >
                <Grid item sx={{ display: { xs: "none", md: "flex" } }}>
                  <Link to="/">
                    <Img
                      alt="GreatLakes surgery Logo"
                      sx={{
                        width: { xs: "250px", lg: "350px" },
                        marginRight: { xs: "20px", sm: "0px" },
                      }}
                      src={GreatLakesLogo}
                    />
                  </Link>
                </Grid>
                <Grid
                  container
                  alignItems="center"
                  sx={{ display: { md: "none" } }}
                >
                  <Grid item>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2, display: { xs: "inline-flex", md: "none" } }}
                    >
                      <MenuIcon sx={{ color: "text.primary" }} />
                    </IconButton>
                  </Grid>
                  <Grid
                    item
                    sx={{ width: "75%", maxWidth: "350px", margin: "0 auto" }}
                  >
                    <Link to="/">
                      <Img
                        alt="GreatLakes surgery Logo"
                        sx={{
                          width: "100%",
                        }}
                        src={GreatLakesLogo}
                      />
                    </Link>
                  </Grid>
                </Grid>
                <Grid item>
                  <Box
                    sx={{
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "0 auto",
                      display: { xs: "none", md: "flex" },
                    }}
                  >
                    <CustomNavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to={"/"}
                      sx={{
                        color: "text.primary",
                        m: { xs: 1, md: 2, lg: 2.5 },
                        fontSize: { md: "14px", lg: "16px" },
                      }}
                    >
                      Home
                    </CustomNavLink>

                    {navItems.map((item) => (
                      <CustomNavLink
                        style={({ isActive }) =>
                          isActive ? activeStyle : undefined
                        }
                        to={item.toLowerCase()}
                        key={item}
                        sx={{
                          color: "text.primary",
                          m: { xs: 1, md: 2, lg: 2.5 },
                          fontSize: { md: "14px", lg: "16px" },
                        }}
                      >
                        {item}
                      </CustomNavLink>
                    ))}
                    <CustomNavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to={"after-surgery"}
                      sx={{
                        color: "text.primary",
                        m: { xs: 1, md: 2, lg: 2.5 },
                        fontSize: { md: "14px", lg: "16px" },
                      }}
                    >
                      After Surgery
                    </CustomNavLink>
                    <CustomNavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to={"about"}
                      sx={{
                        color: "text.primary",
                        m: { xs: 1, md: 2, lg: 2.5 },
                        fontSize: { md: "14px", lg: "16px" },
                      }}
                    >
                      About
                    </CustomNavLink>
                    <BasicMenu />
                    {/* <CustomNavLink
                      style={({ isActive }) =>
                        isActive ? activeStyle : undefined
                      }
                      to="why-list"
                      sx={{
                        color: "#fff",
                        m: { xs: 1, md: 2, lg: 2.5 },
                        fontSize: { md: "14px", lg: "16px" },
                      }}
                    >
                      Why List With Me
                    </CustomNavLink> */}
                    <HashLink to="/#contact" style={{ textDecoration: "none" }}>
                      <ContactButton
                        sx={{
                          fontSize: { md: "14px", lg: "16px" },
                          display: { xs: "none", sm: "flex" },
                          width: { xs: "90px", lg: "120px" },
                          marginLeft: { xs: "15px", lg: "50px" },
                        }}
                      >
                        Contact
                      </ContactButton>
                    </HashLink>
                  </Box>
                </Grid>
              </Grid>
            </Slide>
          </Toolbar>
        </FadeWrapper>
      </AppBar>
      {/* </Grow> */}

      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            zIndex: "999999",
            display: { xs: "block", md: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
