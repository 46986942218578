import { Box, Grid, Typography, styled } from "@mui/material";
import React from "react";
import calendarIcon from "../img/calendar.png";
import callIcon from "../img/call.png";
import locationIcon from "../img/location.png";

const Typo = styled(Typography)(({ theme }) => ({
  textDecoration: "none",
  maxWidth: "max-content",
  "&:hover": {
    transition: "all .2s ease-in-out",
    transform: "scale(1.05)",
    transformOrigin: "center center",
  },
}));
const Gridd = styled(Grid)(({ theme }) => ({
  textDecoration: "none",
  "&:hover": {
    transition: "all .2s ease-in-out",
    transform: "scale(1.05)",
    transformOrigin: "center center",
  },
}));
function Contact({ isChat, toggleIsChat }) {
  return (
    <Grid
      container
      justifyContent="center"
      sx={{ marginTop: { xs: "-50px", md: "-150px" } }}
    >
      <Box
        sx={{
          padding: "20px",
          width: "350px",
          height: "300px",
          backgroundColor: "#28686c",
        }}
      >
        <img
          src={callIcon}
          alt="call us"
          width="40px"
          style={{
            filter: "invert(100%)",
          }}
        />
        <Box
          sx={{
            margin: "10px 0px",
            borderTop: "1px solid rgb(255,255,255, 0.3)",
          }}
        ></Box>
        <Typography
          sx={{ marginBottom: "10px" }}
          variant="h6"
          color="text.white"
        >
          Contact us
        </Typography>
        <Typography sx={{ marginBottom: "30px" }} color="text.white">
          Call us by phone during our regular business hours
        </Typography>

        <a
          style={{
            color: "white",
            textDecoration: "none",
            width: "100%",
          }}
          href="tel:519-979-7227"
        >
          <Gridd
            container
            justifyContent="space-between"
            sx={{ padding: "0px 20px" }}
          >
            <Typography style={{ fontWeight: "bold", display: "inline-flex" }}>
              Tecumseh:
            </Typography>
            <Typography>519-979-7227</Typography>
          </Gridd>
        </a>
        <a
          style={{
            color: "white",
            textDecoration: "none",
            width: "100%",
          }}
          href="tel:519-397-1177"
        >
          <Gridd
            container
            justifyContent="space-between"
            sx={{ padding: "0px 20px" }}
          >
            <Typography style={{ fontWeight: "bold", display: "inline-flex" }}>
              Chatham:
            </Typography>
            <Typography>519-397-1177</Typography>
          </Gridd>
        </a>
      </Box>
      <Box
        sx={{
          padding: "20px",
          width: "350px",
          height: "300px",
          backgroundColor: "#3A959B",
        }}
      >
        <img
          src={locationIcon}
          alt="call us"
          width="40px"
          style={{
            filter: "invert(100%)",
          }}
        />
        <Box
          sx={{
            margin: "10px 0px",
            borderTop: "1px solid rgb(255,255,255, 0.3)",
          }}
        ></Box>
        <Typography
          sx={{ marginBottom: "10px" }}
          variant="h6"
          color="text.white"
        >
          Our Locations
        </Typography>

        <a
          style={{ color: "white", textDecoration: "none" }}
          href="#contact"
          onClick={() => toggleIsChat(false)}
        >
          <Typo
            sx={{
              display: "inline-flex",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Tecumseh:
            <Typography
              sx={{
                marginLeft: "20px",
                marginTop: "2px",
                display: "inline-flex",
              }}
            >
              1825 Manning Rd | Studio 700 Tecumseh | ON | N9K 0A9
            </Typography>
          </Typo>
        </a>
        <a
          style={{
            color: "white",
            textDecoration: "none",
          }}
          href="#contact"
          onClick={() => toggleIsChat(true)}
        >
          <Typo
            sx={{
              display: "inline-flex",
              fontWeight: "bold",
            }}
          >
            Chatham:
            <Typography
              sx={{
                marginLeft: "20px",
                marginTop: "2px",
                display: "inline-flex",
              }}
            >
              197 St. Clair Street | ON | N7L 3J4
            </Typography>
          </Typo>
        </a>
      </Box>
      <Box
        sx={{
          padding: "20px",
          width: "350px",
          height: "300px",
          backgroundColor: "#deeded",
        }}
      >
        <img src={calendarIcon} alt="call us" width="40px" />
        <Box
          sx={{
            margin: "10px 0px",
            borderTop: "1px solid rgb(0,0,0, 0.3)",
          }}
        ></Box>
        <Typography sx={{ marginBottom: "10px" }} variant="h6">
          Hours of Operation
        </Typography>

        <a
          style={{
            textDecoration: "none",
          }}
          href="#contact"
          onClick={() => toggleIsChat(true)}
        >
          <Typo
            sx={{
              display: "inline-flex",
              fontWeight: "bold",
              color: "text.primary",
              marginBottom: "10px",
            }}
          >
            Tecumseh:
            <Typography
              sx={{
                marginLeft: "20px",
                marginTop: "2px",
                display: "inline-flex",
              }}
            >
              Mon to Thurs • 8am - 5pm | Fri • 8am - 3pm
            </Typography>
          </Typo>
        </a>
        <a
          style={{
            textDecoration: "none",
          }}
          href="#contact"
          onClick={() => toggleIsChat(true)}
        >
          <Typo
            sx={{
              display: "inline-flex",
              fontWeight: "bold",
              color: "text.primary",
            }}
          >
            Chatham:
            <Typography
              sx={{
                marginLeft: "20px",
                marginTop: "2px",
                display: "inline-flex",
              }}
            >
              Mon • Closed | Tues • 8am - 5pm | Wed • Closed | Thurs • 8am - 5pm
              | Fri • 8am - 3pm
            </Typography>
          </Typo>
        </a>
      </Box>
    </Grid>
  );
}

export default Contact;
