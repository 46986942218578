import { animated, config, useSpring } from "@react-spring/web";
import React, { useState } from "react";
import { Waypoint } from "react-waypoint";
import styles from "../css/offers.module.css";

function UnderlineT() {
  const [open, toggleOpen] = useState(false);

  const props = useSpring({
    width: open ? "50px" : "0px",
    backgroundColor: open ? "#3A959B" : "white",
    delay: 200,
    config: config.wobbly,
  });
  return (
    <>
      <Waypoint bottomOffset="20%" onEnter={() => toggleOpen(true)} />
      <animated.div className={styles.fill} style={props}></animated.div>
    </>
  );
}

export default UnderlineT;
