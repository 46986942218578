import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionAccordion from "../components/QuestionAccordion";
import UnderlineB from "../components/UnderlineB";

function AfterFacialTrauma() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          After Facial Trauma
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography sx={{ marginBottom: "50px" }}>
        Discharge Instructions for Facial Trauma
      </Typography>
      <QuestionAccordion title="Fractured Nasal Bone (Nose)">
        <ul>
          <li>Keep ice to top of nose for first day if nose is not splinted</li>
          <li>
            If nose is splinted and packed, maintain splint and packing until
            seen in office
          </li>
          <li>No nose blowing</li>
          <li>No heavy lifting or strenuous exercise</li>
          <li>Sleep with head elevated with pillows for first 3 to 4 days</li>
          <li>If nose continues to bleed, call the office</li>
        </ul>
      </QuestionAccordion>
      <QuestionAccordion title="Maxillary Mandible Fractures (Upper and Lower Jaws)">
        <ul>
          <li>Keep ice to area of broken bone for first 2 days</li>
          <li>Sleep with head elevated for first 3 to 4 days</li>
          <li>Keep mouth clean by rinsing and brushing teeth</li>
          <li>Diet - soft foods & liquids only. NO CHEWING!</li>
          <li>
            If elastics or wired shut and 1 or 2 fall off, no need to worry
            until next office visit
          </li>
          <li>No heavy lifting or strenuous exercise</li>
          <li>
            If jaws wired shut, may use straw for delivery of soft foods and
            liquids
          </li>
          <li>
            If sutures have been placed and profuse bleeding from incision site,
            call the office
          </li>
          <li>
            If sutures have been placed and a couple dislodge, no need to worry
          </li>
          <li>
            If swelling increases after the 3rd or 4th day, call the office
          </li>
          <li>
            Although you may be able to open and close your lower jaw, you must
            remember it is considered still broken 4-6 weeks after the injury
          </li>
          <li>
            Plates and screws that have been placed will be maintained unless
            otherwise indicated for removal
          </li>
          <li>
            Remember a numb feeling to the lower lip, chin, or tongue area may
            be normal with the particular fracture. This may or may not resolve
            in time.
          </li>
          <li>
            If fractured jaw not repaired as of yet & sent home, still follow
            above applicable instructions
          </li>
          <li>Clean bars & wires on teeth as well as brushing teeth</li>
        </ul>
      </QuestionAccordion>
      <QuestionAccordion title="Zygomatico-Orbital Fractures (Cheek Bone/Orbital Bone Fractures)">
        <ul>
          <li>
            Keep ice to face in area of fracture for first 1-2 days after
            fracture/surgery
          </li>
          <li>
            Sleep with head elevated with pillows for the first 3 to 4 days
          </li>
          <li>
            Remember that numbness to the fracture side of the face is normal
            for this particular fracture and may or may not resolve with time
          </li>
          <li>Do not sleep on affected side of face</li>
          <li>
            If pain or loss of vision in eye, call the office or go to the
            Emergency Room immediately
          </li>
          <li>
            Double vision may be normal for this type of fracture & may resolve
            with surgery or time
          </li>
          <li>No nose blowing</li>
          <li>No heavy lifting or strenuous exercise</li>
          <li>Apply antibiotic ointment to surgical incision 2 times a day</li>
        </ul>
      </QuestionAccordion>
      <QuestionAccordion title="Dento-Alveolar Fracture (Tooth & Gum Injuries)">
        <ul>
          <li>Soft foods and liquid for diet</li>
          <li>Expect that hot and cold temperatures may cause sensitivity</li>
          <li>
            Expect that root canal treatment may be necessary in the near future
          </li>
          <li>Brush teeth and keep wires clean</li>
          <li>
            If increase in swelling, pain and/or associated bad taste in mouth
            please call the office and make an appointment
          </li>
        </ul>
      </QuestionAccordion>
      <QuestionAccordion title="Lacerations">
        <ul>
          <li>Keep wounds clean and clear of scabs</li>
          <li>
            Apply antibiotic ointment to wounds after cleaning 2 times a day
          </li>
          <li>
            If dressing is placed over wounds, maintain dressing seen in the
            office
          </li>
          <li>
            Avoid sun exposure for first 6 months to a year by applying complete
            block sunscreen to wounds once healed
          </li>
        </ul>
      </QuestionAccordion>
      <Typography
        sx={{
          margin: "0 auto",
          marginTop: "20px",
          textAlign: "center",
          maxWidth: "70%",
        }}
      >
        If you have any questions or concerns don’t hesitate to call the office
        to speak with a nurse. Tecumseh{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-979-7227"
        >
          (519)-979-7227
        </a>{" "}
        or Chatham{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-397-1177"
        >
          (519)-397-1177
        </a>{" "}
      </Typography>
    </Grid>
  );
}

export default AfterFacialTrauma;
