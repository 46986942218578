import { Box, Grid, Typography, styled } from "@mui/material";
import React, { useState } from "react";

const TealButton = styled("div")({
  display: "flex",
  justifyContent: "center",
  border: "2px solid #3A959B",
  color: "#3A959B",
  margin: "20px auto",
  padding: "5px 20px",
  width: "240px",
  backgroundImage: "linear-gradient(#3A959B, #3A959B)",
  backgroundPosition: "50% 50%",
  backgroundRepeat: "no-repeat",
  backgroundSize: "0% 100%",
  transition: "background-size 0.5s, color 0.5s",

  "&:hover": {
    color: "white",
    backgroundSize: "100% 100%",
    cursor: "pointer",
  },
});
const Img = styled("img")({});

function Skills(props) {
  return (
    <Grid
      container
      sx={{
        maxWidth: "500px",
        width: "90%",
        boxShadow: "4px 4px 5px 2px rgb(0,0,0,0.1)",
        margin: "0 auto",
        marginTop: "10px",
      }}
      className="listcolors"
    >
      <Grid
        item
        container
        className="listcolors"
        sx={{ backgroundColor: "rgb(58, 149, 155,0.15)", padding: "10px" }}
      >
        <Grid item xs={4}>
          <Typography variant="h6">Specialty</Typography>
        </Grid>
        <Grid
          item
          xs={8}
          sx={{ paddingLeft: "10px", display: "flex", alignItems: "center" }}
        >
          {props.specialty}
        </Grid>
      </Grid>
      <Grid item container sx={{ padding: "10px" }}>
        <Grid item xs={4}>
          <Typography variant="h6">Education</Typography>
        </Grid>
        <Grid item xs={8} sx={{ paddingLeft: "10px" }}>
          <Typography>
            <ul style={{ marginLeft: "-35px" }}>
              {props.education?.map((item, i) => (
                <li>{item}</li>
              ))}
            </ul>
          </Typography>
        </Grid>
      </Grid>
      {/* <Grid
        item
        container
        sx={{ backgroundColor: "rgb(58, 149, 155,0.2)", padding: "10px" }}
      >
        <Grid item xs={4}>
          <Typography variant="h6">Scope of Practice</Typography>
        </Grid>
        <Grid item xs={8} sx={{ paddingLeft: "10px" }}>
          <Typography>
            <ul style={{ marginLeft: "-35px" }}>
              {props.scope?.map((item, i) => (
                <li>{item}</li>
              ))}
            </ul>
          </Typography>
        </Grid>
      </Grid> */}
    </Grid>
  );
}

function Doc(props) {
  const [show, toggleShow] = useState(false);
  return (
    <Grid item container justifyContent="center" sx={{}}>
      <Grid container justifyContent="center"></Grid>
      <Grid
        item
        container
        xs={12}
        md={3}
        justifyContent={{ xs: "center", md: "flex-end" }}
      >
        <Img
          sx={{
            height: { xs: "270px", sm: "350px", md: "300px", lg: "350px" },
          }}
          src={props.image}
          alt="Doctor"
        />
      </Grid>
      <Grid
        item
        container
        xs={12}
        md={9}
        sx={{
          paddingRight: { xs: "0%", lg: "5%" },
        }}
        flexDirection="column"
      >
        <Grid item container>
          <Grid item xs={12} md={7} sx={{ padding: "30px" }}>
            <Typography
              variant="h4"
              sx={{ marginBottom: "5px", width: "max-content" }}
            >
              {props.doctor}
            </Typography>
            <Typography
              variant="subtitle2"
              color="grey.light"
              sx={{ marginBottom: "40px", width: "max-content" }}
            >
              {props.degrees}
            </Typography>
            <Typography variant="subtitle1" sx={{ marginBottom: "20px" }}>
              {props.intro}
            </Typography>
            {!show ? (
              <TealButton color="teal" onClick={() => toggleShow(true)}>
                {" "}
                Read More
              </TealButton>
            ) : (
              props.children
            )}
          </Grid>
          <Grid item xs={12} md={5}>
            <Skills
              education={props.education}
              scope={props.scope}
              specialty={props.specialty}
            ></Skills>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: "60%",
          borderTop: "1px solid black",
          margin: "30px 0px",
        }}
      />
    </Grid>
  );
}

export default Doc;
