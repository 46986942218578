import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled } from "@mui/system";
import React from "react";
import { Link, useLocation } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logowhited from "../img/logowhited.png";

const Base = styled("div")({
  backgroundColor: "#292D2D",
  display: "flex",
  alignItems: "center",
  minHeight: "150px",
  width: "100%",
  zIndex: "100000",
  padding: "20px",
});

function Footer() {
  let location = useLocation();
  location = location.pathname.toLowerCase();

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const smHeight = useMediaQuery("(max-height:650px)", { noSsr: true });

  return (
    <>
      {matches && location === "/home" && !smHeight ? null : (
        <Base>
          <Grid
            container
            alignItems="center"
            sx={{
              height: "100%",
              width: "100%",
              display: { xs: "none", sm: "flex" },
            }}
          >
            <Grid
              item
              container
              flexDirection="column"
              xs={4}
              justifyContent="center"
              sx={{ paddingLeft: { xs: "0%", md: "10%" } }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography color="text.white">Home</Typography>
              </Link>
              <Link to="downloads" style={{ textDecoration: "none" }}>
                <Typography color="text.white">Download forms</Typography>
              </Link>
              <HashLink to="/#contact" style={{ textDecoration: "none" }}>
                <Typography color="text.white">Contact</Typography>
              </HashLink>
            </Grid>
            <Grid item container xs={4} justifyContent="center">
              <img
                src={logowhited}
                alt="JM"
                style={{ width: "100%", maxWidth: "350px" }}
              />
            </Grid>
            <Grid
              item
              container
              xs={4}
              justifyContent="center"
              sx={{
                paddingRight: { xs: "0%", md: "10%" },
              }}
            >
              <Typography
                variant="body"
                color="text.white"
                sx={{ fontSize: "12px", textAlign: "center" }}
              >
                Site By: Lukas Puckerin Web development
              </Typography>{" "}
            </Grid>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={2}
            sx={{
              height: "100%",
              width: "100%",
              display: { xs: "flex", sm: "none" },
            }}
          >
            <Grid item container xs={12} justifyContent="center">
              <img
                src={logowhited}
                alt="JM"
                style={{
                  width: "70%",
                  maxWidth: "300px",
                  marginBottom: "20px",
                }}
              />
            </Grid>
            <Grid
              container
              flexDirection="row"
              justifyContent="space-around"
              rowGap={2}
              sx={{ paddingLeft: { xs: "0%", md: "10%" } }}
            >
              <Link to="/" style={{ textDecoration: "none" }}>
                <Typography color="text.white">Home</Typography>
              </Link>
              <Link to="downloads" style={{ textDecoration: "none" }}>
                <Typography color="text.white">Download forms</Typography>
              </Link>
              <HashLink to="/#contact" style={{ textDecoration: "none" }}>
                <Typography color="text.white">Contact</Typography>
              </HashLink>
            </Grid>

            <Grid
              item
              container
              xs={4}
              justifyContent="center"
              sx={{
                paddingRight: { xs: "0%", md: "10%" },
              }}
            >
              <Typography
                variant="body"
                color="text.white"
                sx={{ fontSize: "12px", textAlign: "center" }}
              >
                Site By: Lukas Puckerin Web development
              </Typography>{" "}
            </Grid>
          </Grid>
        </Base>
      )}
    </>
  );
}

export default Footer;
