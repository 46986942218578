import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionAccordion from "../components/QuestionAccordion";
import UnderlineB from "../components/UnderlineB";

function MultipleTeeth() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          After the Removal of Multiple Teeth
          <UnderlineB />
        </Typography>
      </Grid>
      <QuestionAccordion title="Bleeding">
        After tooth extraction, it’s important for a blood clot to form to stop
        the bleeding and begin the healing process. That’s why we ask that you
        bite on a gauze pad for about 30 minutes after the appointment. If the
        bleeding or oozing still persists, place another gauze pad and bite for
        another 30 minutes. After the blood clot forms, it is important not to
        disturb or dislodge the clot as it aids healing. Do not rinse
        vigorously, suck on straws, smoke, or brush teeth next to the extraction
        site for about 48 hours. These activities will dislodge the clot and
        slow down the healing process.
      </QuestionAccordion>
      <QuestionAccordion title="Pain">
        You should begin taking pain medication as soon as you feel the local
        anesthetic wearing off. For mild to moderate pain, Tylenol or Extra
        Strength Tylenol may be taken as directed. Ibuprofen (Advil or Motrin)
        may be taken instead of Tylenol. For severe pain, the prescribed
        medication should be taken as directed. Do not take any of the above
        medication if you are allergic, or have been instructed by your doctor
        not to take it. A sore throat may develop. The muscles of the throat are
        near the extraction sites. Swelling into the throat muscles can cause
        pain. This is normal and should subside in 2-3 days.
      </QuestionAccordion>
      <QuestionAccordion title="Swelling">
        Swelling is a normal occurrence after surgery. To minimize swelling,
        apply an ice bag, or a plastic bag, or towel filled with ice on the
        cheek/lip in the area of surgery. Apply the ice intermittently for 20-30
        minute intervals as much as possible, for the first 48 hours. The area
        operated on will swell reaching a maximum in 2-3 days. Swelling and
        discoloration around the eye may occur. The application of a moist warm
        towel will help eliminate the discoloration quicker. The towel should be
        applied continuously for as long as tolerable beginning 48 hours after
        surgery (remember ice packs are used for the first 48 hours only).
      </QuestionAccordion>
      <QuestionAccordion title="Oral Hygiene">
        Good oral hygiene is essential to good healing. Warm salt water
        (teaspoon of salt in a cup of warm water) rinses should be used at least
        4-5 times a day, especially after meals. Brushing your teeth is no
        problem. Be gentle initially with brushing the surgical areas. If the
        corners of the mouth are stretched, they may dry out and crack. Your
        lips should be kept moist with an ointment like Vaseline. There may be a
        slight elevation of temperature for 24-48 hours. If temperature
        continues, notify our office.
      </QuestionAccordion>
      <QuestionAccordion title="Diet">
        Drink plenty of fluids. Avoid hot liquids or food fort he day of
        surgery. Soft food and liquids should be eaten until you can comfortably
        progress your diet.
      </QuestionAccordion>
      <QuestionAccordion title="Activity">
        Limit vigorous exercise for the next 24 hours as this will increase
        blood pressure and may result in more bleeding from the extraction site.
        Be aware that your normal nourishment may be reduced. Exercise may
        weaken you. If you get lightheaded, stop exercising.
      </QuestionAccordion>
      <QuestionAccordion title="Post IV Sedation">
        After IV sedation, the patient is required to have a responsible adult
        to accompany them for a minimum of 18 hours or longer if drowsiness or
        dizziness persists. NO exceptions!! The patient should not drive a
        vehicle, operate machinery, make important financial/life decisions,
        consume alcohol or other drugs with sedative properties for 24 hours
        following the anesthetic.
      </QuestionAccordion>
      <QuestionAccordion title="Dentures">
        If immediate dentures have been inserted, sore spots may develop. In
        most cases, your dentist will see you within 24-48 hours after surgery
        and make the necessary adjustments to relieve those sore spots. Failure
        to do so may result in severe denture sores, which may prolong the
        healing process.
      </QuestionAccordion>
      <Typography sx={{ marginTop: "10px" }}>
        After, a few days you will feel fine and can resume your normal
        activities.{" "}
        <span style={{ fontWeight: "bold" }}>
          {" "}
          If you have heavy bleeding, severe pain, continued swelling beyond 4-5
          days, or a reaction to the medications, call our office immediately.
        </span>
      </Typography>
      <Typography
        sx={{
          margin: "0 auto",
          marginTop: "20px",
          textAlign: "center",
          maxWidth: "70%",
        }}
      >
        If you have any questions or concerns don’t hesitate to call the office
        to speak with a nurse. Tecumseh{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-979-7227"
        >
          (519)-979-7227
        </a>{" "}
        or Chatham{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-397-1177"
        >
          (519)-397-1177
        </a>{" "}
      </Typography>
    </Grid>
  );
}

export default MultipleTeeth;
