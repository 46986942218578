import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionAccordion from "../components/QuestionAccordion";
import UnderlineB from "../components/UnderlineB";

function Extraction() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          Extraction of Routine Teeth
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography>
        You, your Dentist and your surgeon may determine that you need a tooth
        extraction for any number of reasons. Some teeth are extracted because
        they are severely decayed; others may have advanced periodontal disease,
        or have broken in a way that cannot be repaired. Other teeth may need
        removal because they are poorly positioned in the mouth (such as
        impacted teeth), or in preparation for orthodontic treatment. The
        removal of a single tooth can lead to problems related to your chewing
        ability, problems with your jaw joint, and shifting teeth, which can
        have a major impact on your dental health. To avoid these complications,
        in most cases, your surgeon will discuss alternatives to extractions as
        well as replacement of the extracted tooth.
      </Typography>
      <iframe
        width="600"
        height="360"
        src="https://www.youtube.com/embed/BvGQ4ilPKws"
        title="Extractions"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        allowfullscreen
        style={{
          marginTop: "20px",
          marginBottom: "50px",
          width: "100%",
          maxWidth: "600px",
          height: "360",
        }}
      ></iframe>
      <QuestionAccordion title="The Extraction Process">
        At the time of extraction your surgeon will need to numb your tooth,
        jawbone and gums that surround the area with a local anesthetic.
        Additional forms of anesthesia are available, if needed, although they
        may require another appointment. During the extraction process you will
        feel a lot of pressure. This is from the process of firmly rocking the
        tooth in order to widen the socket for removal. You may feel pressure
        during your extractions, but should not feel pain. If you do feel pain
        at any time during the extraction please let us know right away. We will
        stop what we are doing in order to make you more comfortable before
        completing your extraction.
      </QuestionAccordion>
      <QuestionAccordion title="Sectioning a Tooth">
        Some teeth require sectioning. This is a very common procedure done when
        a tooth is so firmly anchored in its socket or the root is curved and
        the socket can’t expand enough to remove it. The doctor simply cuts the
        tooth into sections then removes each section one at a time.
      </QuestionAccordion>
    </Grid>
  );
}

export default Extraction;
