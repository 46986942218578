import { Grid, Typography } from "@mui/material";
import React from "react";
import UnderlineB from "../components/UnderlineB";

function OralPathology() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          Oral Pathology
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography sx={{ marginBottom: "50px" }} className="listcolors">
        The inside of the mouth is normally lined with a special type of skin
        (mucosa) that is smooth and coral pink in color. Any alteration in this
        appearance could be a warning sign for a pathological process. The most
        serious of these is oral cancer. The following can be signs at the
        beginning of a pathologic process or cancerous growth:
        <ul>
          <li>
            Reddish patches (erythroplasia) or whitish patches (leukoplakia) in
            the mouth
          </li>
          <li>
            A sore that fails to heal and bleeds easily *A lump or thickening on
            the skin lining the inside of the mouth
          </li>
          <li>Chronic sore throat or hoarseness</li>
          <li>Difficulty in chewing or swallowing</li>
        </ul>
        These changes can be detected on the lips, cheeks, palate and gum tissue
        around the teeth, tongue, face and/or neck. Pain does not always occur
        with pathology. However, any patient with facial and/or oral pain
        without an obvious cause or reason may also be at risk for oral cancer.
        We would recommend performing an oral cancer self-examination monthly
        and remember that your mouth is one of your body’s most important
        warning systems. Do not ignore suspicious lumps or sores. Please contact
        us so we may help. Larger or more suspicious lesions require
        investigation by obtaining a piece of the tissue in question. This is
        traditionally known as a biopsy. The tissue abnormality will need to be
        anesthetized either locally, or in conjunction with some type of
        sedative technique. It is then placed in a jar of tissue preservation
        and sent to a lab where a pathologist will identify it under a
        microscope.
      </Typography>
    </Grid>
  );
}

export default OralPathology;
