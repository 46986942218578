import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
      biiig: 2000,
    },
  },
  palette: {
    primary: {
      main: "#515151",
      darker: "rgb(123, 122, 94)",
    },
    secondary: {
      main: "#7E7E7E",
      darker: "#7E7E7E",
    },
    teal: {
      main: "#3A959B",
      darker: "#29696e",
      lighter: "#44C7CD",
    },
    blue: {
      main: "#114c74",
      darker: "#0b3551",
      lighter: "#406f8f",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
    whitebg: {
      main: "rgb(0,0,0,0.4)",
      contrastText: "#000000",
    },
    white: {
      main: "#ffffff",
      contrastText: "#000000",
    },
    black: {
      main: "#000000",
      contrastText: "#ffffff",
    },
    grey: {
      main: "#222222",
      medium: "#515151",
      light: "#7E7E7E",
    },
    lightgrey: {
      main: "#7E7E7E",
    },
    text: {
      primary: "#222222",
      secondary: "#222222",
      medium: "#515151",
      light: "#7E7E7E",
      white: "#ffffff",
    },
    purpleT: {
      main: "rgb(33, 35, 69, 0.95)",
      contrastText: "#ffffff",
    },
    greenT: {
      main: "rgb(123, 122, 94, 0.95)",
      sub: "#ffffff",
      contrastText: "#ffffff",
    },
  },
  typography: {
    fontFamily: '"Karla", "Roboto", sans-serif;',
    h1: {
      fontFamily: "'Playfair Display', serif;",
    },
    h2: {
      fontFamily: "'Playfair Display', serif;",
    },
    h3: {
      fontFamily: "'Playfair Display', serif;",
    },
    h4: {
      fontFamily: "'Playfair Display', serif;",
    },
    h5: {
      fontFamily: "'Playfair Display', serif;",
    },
    banana: {
      fontFamily: '"Montserrat", sans-serif',
    },
    monh4: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 400,
      fontSize: "2.215rem",
      lineHeight: "1.235",
      letterSpacing: "0.00735em",
    },
    monh5: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 400,
      fontSize: "1.5rem",
      lineHeight: "1.334",
      letterSpacing: "0em",
    },
    monh6: {
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: 400,
      fontSize: "1.25rem",
      lineHeight: "1.167",
      letterSpacing: "0em",
    },
    body: {
      fontFamily: '"Montserrat", sans-serif',
    },
  },
});
