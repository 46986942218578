import { CssBaseline, ThemeProvider, responsiveFontSizes } from "@mui/material";
import { useEffect } from "react";
import { HelmetProvider } from "react-helmet-async";
import { Route, Routes, useLocation } from "react-router-dom";
import { theme } from "./Theme";
import About from "./about/About";
import AfterSurgery from "./aftersurgery/AfterSurgery";
import Footer from "./components/Footer";
import Header from "./components/Header";
import NoPage from "./components/NoPage";
import "./css/main.css";
import Doctors from "./doctors/Doctors";
import Downloads from "./downloads/Downloads";
import Home from "./home/Home";
import Payment from "./payment/Payment";
import Staff from "./staff/Staff";
import Surgery from "./surgery/Surgery";

import Referral from "./referral/Referral";

const themed = responsiveFontSizes(theme);

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <HelmetProvider>
      <div className="App">
        <ThemeProvider theme={themed}>
          <CssBaseline />
          <Header />
          <Routes>
            {/* <Route path="*" element={<Maintenance />} /> */}
            <Route path="" element={<Home />} />
            <Route path="surgery/*" element={<Surgery />} />
            <Route path="after-surgery/*" element={<AfterSurgery />} />
            <Route path="doctors/*" element={<Doctors />} />
            <Route path="about/*" element={<About />} />
            <Route path="staff/*" element={<Staff />} />
            <Route path="downloads/*" element={<Downloads />} />
            <Route path="payment/*" element={<Payment />} />
            <Route path="referral/*" element={<Referral />} />
            <Route path="/*" element={<NoPage />} />
          </Routes>

          <Footer />
        </ThemeProvider>
      </div>
    </HelmetProvider>
  );
}

export default App;
