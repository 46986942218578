import { Box, Typography } from "@mui/material";
import React from "react";

function NoPage() {
  return (
    <Box
      sx={{
        height: "100vh",
        backgroundColor: "rgb(200,200,200)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" color="text.primary">
        No Page Found Here
      </Typography>
    </Box>
  );
}

export default NoPage;
