import { Grid, Typography } from "@mui/material";
import React from "react";
import QuestionAccordion from "../components/QuestionAccordion";
import UnderlineB from "../components/UnderlineB";

function Placement() {
  return (
    <Grid
      container
      flexDirection="column"
      alignItems={{ xs: "center", sm: "flex-start" }}
      sx={{
        width: "100%",
        maxWidth: "950px",
        backgroundColor: "white !important",
        zIndex: 1000,
      }}
    >
      <Grid item>
        <Typography
          variant="h5"
          sx={{ width: "100%", maxWidth: "max-content" }}
        >
          After Placement of Dental Implants
          <UnderlineB />
        </Typography>
      </Grid>
      <Typography sx={{ marginBottom: "50px" }}>
        Do not disturb the wound. Avoid rinsing, spitting, or touching the wound
        on the day of surgery. There will be a metal healing abutment protruding
        through the gingival (gum) tissue.
      </Typography>
      <QuestionAccordion title="Bleeding">
        Some bleeding or redness in the saliva is normal for 24 hours. Excessive
        bleeding (your mouth fills up rapidly with blood) can be controlled by
        biting on a gauze pad placed directly on the bleeding wound for 30-60
        minutes.{" "}
        <span style={{ fontWeight: "bold" }}>
          If bleeding continues, please the office for further instructions.{" "}
        </span>
      </QuestionAccordion>
      <QuestionAccordion title="Swelling">
        Swelling is a normal occurrence after surgery. To minimize swelling,
        apply an ice bag, or a plastic bag, or towel filled with ice on the
        cheek/lip in the area of surgery. Apply the ice intermittently for 20-30
        minute intervals as much as possible, for the first 48 hours.
      </QuestionAccordion>
      <QuestionAccordion title="Diet">
        Drink plenty of fluids. Avoid hot liquids or food. Soft food and liquids
        should be eaten on the day of surgery. Return to a normal diet as soon
        as possible unless otherwise directed. Do not use implant as a tooth
        until directed.
      </QuestionAccordion>
      <QuestionAccordion title="Pain">
        You should begin taking pain medication as soon as you feel the local
        anesthetic wearing off. For mild to moderate pain, Tylenol or Extra
        Strength Tylenol may be taken as directed. Ibuprofen (Advil or Motrin)
        may be taken instead of Tylenol. For severe pain, the prescribed
        medication should be taken as directed. Do not take any of the above
        medication if you are allergic, or have been instructed by your doctor
        not to take it.
      </QuestionAccordion>
      <QuestionAccordion title="Antibiotics">
        Be sure to take the prescribed antibiotics as directed to help prevent
        complications.
      </QuestionAccordion>
      <QuestionAccordion title="Oral Hygiene">
        Good oral hygiene is essential to good healing. Warm salt water
        (teaspoon of salt in a cup of warm water) rinses should be used at least
        4-5 times a day, especially after meals. Use the peridex script as
        prescribed. Brushing your teeth is no problem. Be gentle initially with
        brushing the surgical areas.
      </QuestionAccordion>
      <QuestionAccordion title="Activity">
        Keep physical activities to a minimum immediately following surgery. If
        you are considering exercise, throbbing or bleeding may occur. If this
        occurs, you should discontinue exercising. Keep in mind that you are
        probably not taking normal nourishment. This may weaken you and further
        limit your ability to exercise.
      </QuestionAccordion>
      <QuestionAccordion title="Wearing your Prosthesis">
        Partial dentures, flippers, or full dentures will need to be reduced to
        ensure they do not apply any pressure to the implant, or bony graft
        sites. These should be worn for esthetics only. They should not be used
        to chew or function with, as this may compromise the implant or bone
        graft. This may be fitted the first or second visit after your surgery.
      </QuestionAccordion>
      <QuestionAccordion title="Post IV Sedation">
        After IV sedation, the patient is required to have a responsible adult
        to accompany them for a minimum of 18 hours or longer if drowsiness or
        dizziness persists. NO exceptions!! The patient should not drive a
        vehicle, operate machinery, make important financial/life decisions,
        consume alcohol or other drugs with sedative properties for 24 hours
        following the anesthetic.
      </QuestionAccordion>
      <Typography
        sx={{
          margin: "0 auto",
          marginTop: "20px",
          textAlign: "center",
          maxWidth: "70%",
        }}
      >
        If you have any questions or concerns don’t hesitate to call the office
        to speak with a nurse. Tecumseh{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-979-7227"
        >
          (519)-979-7227
        </a>{" "}
        or Chatham{" "}
        <a
          style={{
            textDecoration: "none",
            fontWeight: "bold",
            color: "#3A959B",
          }}
          href="tel:519-397-1177"
        >
          (519)-397-1177
        </a>{" "}
      </Typography>
    </Grid>
  );
}

export default Placement;
